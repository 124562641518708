import React, { useState } from "react";
import MenuLeft from "../components/menu-left";
import TabsMain from "../components/tabs-main";
import ModalComponent from "../components/ModalComponent";
import DropdownForm from "../components/dropdownForm/DropdownForm";
import Footer from "../components/footer/Footer";

export default function Profile() {
    const ArrayMap = [1, 2, 3, 4, 5, 6, 7, 8];
    const [show, setShow] = useState(false);
    const dropdownOptions = ["Opción 1", "Opción 2", "Opción 3"];
    const handleOptionSelect = (option) => {
      console.log("Opción seleccionada:", option);
    };

    const [tab, setTab] = useState({
      id: 1,
      name: "Todos",
    });

  const optionTabs = [
    {
      id: 1,
      name: "Todos",
    },
    {
      id: 2,
      name: "Emitidos",
    },
    {
      id: 3,
      name: "Finalizados",
    },
    {
      id: 4,
      name: "Abandonado",
    },
  ];

  return (
    <>
      <div className="interna comunidad_solicitudes">
        <div className="interna libro comunidad">
          <div className="position_header position ">
            <div className="seccion_interna">
              <div className="ui_container ui_container--946">
                <MenuLeft />
                <div className="col01 w-71 contenido">
                  <div className="box body d-flex flex-column">
                    <div
                      id="anuncio01"
                      className="ui_slider"
                      style={{ overflow: "hidden" }}
                    >
                      <div className="owl-carousel owl-theme owl-loaded">
                        <div className="item">
                          <div className="banner">
                            <img
                              className="img-banner oculta_movile"
                              src="img/interna/profile/banner/profile.png"
                              alt=""
                              style={{ width: "100%" }}
                            />
                            <button
                              id="editar-perfil"
                              onClick={() => setShow(true)}
                              type="button"
                              className="btn-rosado cursor-pointer"
                              style={{ zIndex: 10, cursor: "pointer" }}
                            >
                              Editar perfil
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="contenido">
                      <div className="box body" style={{ paddingLeft: 30 }}>
                        <div
                          className="box Party"
                          style={{
                            marginTop: "-20px",
                            zIndex: 100,
                            position: "relative",
                            display: "flex",
                          }}
                        >
                          <img
                            className="circulo"
                            src="img/interna/profile/logotipo.png"
                            alt=""
                          />
                          <div>
                            <div
                              className="circulo-texto"
                              style={{ marginTop: "30px" }}
                            >
                              somos Party
                            </div>
                          </div>
                        </div>

                        <ul className="lista-infor01">
                          <li>
                            <img src="img/interna/somos-party/p.jpg" alt="" />{" "}
                            <span>Pago con Paypal</span>
                          </li>
                          <li>
                            <img src="img/interna/somos-party/f.jpg" alt="" />
                            <span>facebook.com/AleeO/</span>
                          </li>
                          <li>
                            <img src="img/interna/profile/tuiter.png" alt="" />
                            <span>twitter.com/AleeO/</span>
                          </li>
                        </ul>

                        <ul className="box ui_cols ui_cols--books_card_button">
                          <li className="col01 w-100 oculta_movile">
                            <div className="box informacion">
                              <div className="titulo">Información: </div>
                              <p>
                                Qin Tian fue llevado a una isla desierta debido
                                a un naufragio. Todo lo que tiene equipado es un
                                sistema de supervivencia y una navaja. Tiene que
                                empezar de cero. Construyendo un campamento,
                                haciendo fuego y pescando, tiene que hacer todo
                                lo posible para sobrevivir. ¡Afortunadamente,
                                hay muchas bellezas que lo acompañan! Diosa de
                                la escuela, hermana rubia, loli violenta...
                              </p>
                            </div>
                          </li>
                          <li id="zona-blue" className="col01 w-100 div">
                            <div className="lbox_container">
                              <div className="estadistica oculta_desktop">
                                <ul className="d-flex">
                                  <li className="d-flex">
                                    <div className="item d-flex">
                                      8.9{" "}
                                      <img
                                        src="img/interna/comunidad_libro_detalle/estrella_azul.png"
                                        alt=""
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                      />
                                    </div>
                                    <div className="item">Puntuación</div>
                                  </li>
                                  <li className="d-flex">
                                    <div className="item">8k</div>
                                    <div className="item">Seguidores</div>
                                  </li>
                                  <li className="d-flex">
                                    <div className="item">235</div>
                                    <div className="item">Libros</div>
                                  </li>
                                  <li className="d-flex">
                                    <div className="item">1</div>
                                    <div className="item">Top</div>
                                  </li>
                                </ul>
                              </div>
                              <div className="box texto-descriptivo oculta_desktop">
                                <p>
                                  Qin Tian fue llevado a una isla desierta
                                  debido a un naufragio. Todo lo que tiene
                                  equipado es un sistema de supervivencia y una
                                  navaja. Tiene que empezar de cero.
                                  Construyendo un campamento, haciendo fuego y
                                  pescando, tiene que hacer todo lo posible para
                                  sobrevivir. ¡Afortunadamente, hay muchas
                                  bellezas que lo acompañan! Diosa de la
                                  escuela, hermana rubia, loli violenta...
                                </p>
                              </div>
                              <div className="box red oculta_desktop">
                                <span>
                                  Ver más{" "}
                                  <img
                                    src="img/interna/comunidad_libro_detalle/flecha-red.png"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="box nuestro-libros">
                                <h5>
                                  <img
                                    className="oculta_desktop"
                                    src="img/interna/comunidad_libro_detalle/flecha-azul.png"
                                    alt=""
                                  />
                                </h5>
                              </div>
                            </div>
                            <div className="box grid-tab">
                              <TabsMain
                                optionTabs={optionTabs}
                                setTab={setTab}
                                tab={tab}
                              />

                              <ul className="ui_blocks tab-head oculta_movile">
                                <li className="ui_blocks__li">
                                  <div className="ui_cols ui_cols--order_list mb-4">
                                    <div className="ui_col">
                                      <div className="ui_paragraph_12 ui_paragraph_12--medium">
                                        <span>Libros 1-15 de 280</span>
                                      </div>
                                    </div>

                                    <div className="ui_col">
                                      <div className="ui_order_list">
                                        <div className="ui_order_list--dropdown dropdown">
                                          <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu2"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            Ordenar por:
                                            <span id="dropdown_id">
                                              Recomendados
                                            </span>
                                          </button>
                                          <ul
                                            className="dropdown-menu cbo_libro"
                                            aria-labelledby="dropdownMenu2"
                                          >
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <span className="libro_titulo">
                                                  ORDENADO POR
                                                </span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>
                                                  Últimas actualizaciones
                                                </span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>Recomendados</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>Más populares</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>Mejor valorados</span>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="ui_order_list--dropdown dropdown">
                                          <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu2"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            Mostrar:
                                            <span id="dropdown_id">15</span>
                                          </button>
                                          <ul
                                            className="dropdown-menu cbo_libro"
                                            aria-labelledby="dropdownMenu2"
                                          >
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>20</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>25</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>30</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item dropdown-item--ui"
                                                type="button"
                                              >
                                                <div className="ui_dropdown_circle">
                                                  <div className="ui_dropdown_circle__check"></div>
                                                </div>
                                                <span>35</span>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                        <ul className="ui_order_list_button tabs">
                                          <li className="ui_order_list_button__item active">
                                            <figure>
                                              <img
                                                src="img/svg/icon_order_list_1.svg"
                                                alt=""
                                              />
                                            </figure>
                                          </li>
                                          <li className="ui_order_list_button__item">
                                            <figure>
                                              <img
                                                src="img/svg/icon_order_list_2.svg"
                                                alt=""
                                              />
                                            </figure>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                id="tab1"
                                className="tabcontent"
                                style={{ display: "block" }}
                              >
                                <div className="box">
                                  <ul
                                    className="catalogo custom-grid"
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "repeat(3, 159px)",
                                      gridColumnGap: "60px",
                                      gridRowGap: "10px",
                                    }}
                                  >
                                    {ArrayMap.map(() => (
                                      <li className="ibanner">
                                        <div
                                          className="item"
                                          style={{ width: "100%", margin: 0 }}
                                        >
                                          <img
                                            src="img/interna/tab01/1.png"
                                            alt=""
                                            style={{
                                              width: "100%",
                                              height: "100",
                                              margin: 0,
                                            }}
                                          />
                                        </div>
                                        <div className="item text">
                                          Amamame con suavidad
                                        </div>
                                        <div className="ui_recommended_tag">
                                          <span style={{ color: "white" }}>
                                            Novela
                                          </span>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      
      <ModalComponent show={show} setShow={setShow}>
        <div className="modal-body">
          <div className="titulo-blue p-3">EDITAR PERFIL</div>
          <div
            className="ui_btn_close "
            data-dismiss="modal "
            onClick={() => setShow(false)}
          >
            <figure className="ui_btn_close__figure">
              <img
                loading="lazy "
                src="img/svg/icon_close_FFFFFF.svg "
                alt=""
              />
            </figure>
          </div>
          <div className="box fom-profile">
            <div className="d-flex flex-column">
              <div className="box form">
                <div className="ibillete">
                  <span className="mb-0" style={{ fontSize: 14 }}>
                    SOBRE TI
                  </span>
                  <p className="mb-5">Cuéntanos más sobre el libro</p>
                </div>
                <div className="ibillete">
                  <div className="group-date historia">
                    <span>Apodo</span>
                    <input className="" type="text" />
                  </div>
                  <div className="group-date historia">
                    <span>Nombres</span>
                    <input className="" type="text" />
                  </div>
                </div>

                <div className="ibillete">
                  <div className="group-date historia">
                    <span>Apellidos</span>
                    <input className="" type="text" />
                  </div>
                  <div className="group-date historia">
                    <DropdownForm
                      options={dropdownOptions}
                      onSelect={handleOptionSelect}
                      label={"Selecciona tu país"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column px-4">
                <span>¿con qué sexo te identificas?</span>

                <div className="d-flex justify-content-between w-50">
                  <div className="d-flex">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                    />
                    <span>Hombre</span>
                  </div>
                  <div className="d-flex">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                    />
                    <span>Mujer</span>
                  </div>
                  <div className="d-flex">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                    />
                    <span>Otro</span>
                  </div>
                </div>
                <div
                  className="group-date historia mt-4"
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #5F36DA",
                    boxShadow: "0px 1px 1px rgba(51, 154, 240, 0.1)",
                    borderRadius: "8px",
                    maxWidth: "100%",
                    height: "108px",
                    padding: "5px 20px",
                    display: "inline-block",
                    marginRight: "30px",
                    width: "100%",
                    marginBlock: 0,
                  }}
                >
                  <span>Descripción</span>
                  <textarea className="w-100" rows={3}></textarea>
                </div>
              </div>

              <div className="w-100 p-2 d-flex justify-content-center">
                <button
                  id="guardar-cambios"
                  type="button"
                  className="btn-rosado mt-4 px-1"
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}

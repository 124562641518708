import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./style.css";

const Dropdown = ({ options, onSelect, label, initialValue }) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedValue(option.item || option);
    onSelect(option);
    setIsOpen(false);
  };
  

  return (
    <div className="custom-dropdown-custom w-100" onClick={() => setIsOpen(!isOpen)}>
      <div className="d-flex">
        <span>{label}</span>

        {selectedValue !== label && <span>{selectedValue}</span>}
        {!isOpen ? (
          <BiChevronDown color="#5F36DA" />
        ) : (
          <BiChevronUp color="#5F36DA" />
        )}
      </div>

      {isOpen && (
        <ul className="dropdown-menu-custom">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionSelect(option.item || option)}>
              { option.item || option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import EndpointCore from '../../../pages/Global';
import GoogleLogin from './GoogleLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';

const Registro = ({ cerrarModal, cambioVentanaRegistro }) => {

  const googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [activeWindow, setActiveWindow] = useState('registro');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token'));
  const [googleLoginInfo, setGoogleLoginInfo] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(token);
  }, []);

  function cambioVentana(e) {
    setActiveWindow(e);
    setForgotPassword(false);
    console.log("Cambio de ventana en Registro:", e);
    cambioVentanaRegistro(e); // Llama a la función proporcionada como prop
  }

  const handleLogin = () => {
    if (!userName || !password) {
      toastr.error('Todos los campos son obligatorios.');
      return;
    }

    fetch(EndpointCore.login, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ usuario_identificador: userName, password }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.token) {
          // Logueo exitoso
          localStorage.setItem('token', data.token);
          window.location.reload();
        } else {
          // Error de autenticación
          toastr.error(data.msg);
        }
      })
      .catch(error => {
        console.error('Error al autenticar:', error);
        toastr.error('Error al autenticar. Por favor, inténtalo de nuevo.');
      });
  };

  const handleForgetPassword = () => {
    if (!isValidEmail(forgotPasswordEmail)) {
      toastr.error('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    fetch(EndpointCore.forgetPassword, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: forgotPasswordEmail }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) { // Verificar el estado de la respuesta
          toastr.success(data.msg);
          setForgotPassword(false); // Cambiar el estado solo si la respuesta es exitosa
        } else {
          toastr.error(data.msg);
        }
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
        toastr.error('Error al enviar la solicitud. Por favor, inténtalo de nuevo.');
      });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCloseSesion = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <div className='html'>
      <ul id='ul-registro' className='info-login'>
          <>
            <li><span>Bienvenido de vuelta</span></li>
            <li>
              <span>Inicia sesión para seguir disfrutando las mejores historias en español </span>
            </li>
            <li>
              <div className="caja" style={{ display: forgotPassword ? 'none' : 'block' }}>
                <label>Apodo o correo electrónico</label>
                <input className='custom-input-text' type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
              </div>
            </li>
            <li>
              <div className="caja" style={{ display: forgotPassword ? 'none' : 'block' }}>
                <label>Contraseña</label>
                <input  className='custom-input-text' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </li>
            {forgotPassword ? (
              <div>
                <li>
                  <div className="caja">
                    <label>Ingresa tu correo electrónico</label>
                    <input
                      className='custom-input-text'
                      type="text"
                      value={forgotPasswordEmail}
                      onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    />
                  </div>
                </li>
                <li>
                  <button className="btn-rosado" type="button" onClick={handleForgetPassword}>
                    Enviar correo de restablecimiento
                  </button>
                </li>
              </div>
            ) : (
              <li><a  onClick={() => setForgotPassword(true)}><span>¿Olvidaste tu contraseña?</span></a></li>
            )}
            <li>
              <button className="btn-rosado" type="button" onClick={handleLogin} style={{ display: forgotPassword ? 'none' : 'block',}}>
                Iniciar sesión
              </button>
            </li>
            <li style={{ display: forgotPassword ? 'none' : 'block' }}>
              <GoogleOAuthProvider clientId={String(googleAuthClientId)}>
                      <GoogleLogin setGoogleLoginInfo={setGoogleLoginInfo} cambioVentana={cambioVentana} buttonText={'Ingresa con Google'} />
                    </GoogleOAuthProvider>
              </li>
              <li className='linea_de_puntos'></li>
            <li><a  ><span>¿Aún no tienes una cuenta?</span></a></li>
            <li><a  onClick={() => cambioVentana('inicio')} ><span>Únete ahora</span></a></li>
          </>
      </ul>
    </div>
  );
};
export default Registro;

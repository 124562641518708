import React from "react";
import { Modal} from "react-bootstrap";
import "./style.css";

export default function ModaComponent({ children, show, setShow}) {
  const handleClose = () => setShow(false);

  return (
    <div >
      <Modal show={show} onHide={handleClose}  >
        {/* <Modal.Header closeButton>
        <Modal.Title>{}</Modal.Title>
      </Modal.Header> */}
        <Modal.Body >{children}</Modal.Body >
      </Modal>
    </div>
  );
}

import "../assets/css/App.css";
import "../assets/css/interna.css";
import "../assets/css/movil.css";
import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import Menu from "../components/menu/Menu";
import Banner from "../components/banner/Banner";
import Footer from "../components/footer/Footer";
import Acordeon from "../components/acordeon/Acordeon";
import Carrusel from "../components/carrusel/Carrusel";
import LittleBook from "../components/little-book/LittleBook";
import EndpointCore from "./Global";
import ReportarDropdown from "../components/reportarDropdown/ReportarDropdown";
import BannerAdssense from "../components/BannerAdssense";
import ChaptersDropdown from "../components/dropdown/ChaptersDropdown";
import CustomImageLoader from "../components/CustomLoader/CustomImageLoader";
import CommentBook from "../components/comments/CommentBook";
import useAuth from "../hook/useAuth";



function BookDetail() {
  const [showReportDropdown, setShowReportDropdown] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(localStorage.getItem('token'));
  const [isExpanded, setIsExpanded] = useState(true);
  const [itemsRecommendation, setItemsRecommendation] = useState([]);
  const location = useLocation();
  const { titulo } = useParams();
  const [libroData, setLibroData] = useState(location.state ? location.state.libroData : null);
  const [loading, setLoading] = useState(!libroData);
  const navigate = useNavigate();
  const ancho = window.innerWidth;

  const {auth} = useAuth();
  const userId = auth.id;
  console.log(auth)

  useEffect(() => {
    if (!libroData) {
      const tituloOriginal = titulo.replace(/-/g, ''); // Eliminar guiones
  
      fetch(`${EndpointCore.searchBookByTitle(tituloOriginal)}`)
        .then(response => response.json())
        .then(result => {
          // Asegúrate de que `items` esté presente y tenga al menos un elemento
          if (result && result.items && result.items.length > 0) {
            const foundBook = result.items[0]; // Extrae el primer libro del array
            const libroDataStructure = {
              id: foundBook.bookCode, // Asegúrate de usar el campo correcto
              foto: foundBook.images[0].image.path,
              fotoCover: foundBook.images.length > 1 ? foundBook.images[1].image.path : '',
              titulo: foundBook.bookTitle,
              tituloAlternativo: foundBook.bookAlternativeTitle,
              autor: foundBook.groupCreated.groupName,
              groupCode: foundBook.groupCreated.groupCode,
              novela: foundBook.bookType.name.value,
              time: `Hace ${foundBook.bookHourLatest} horas`,
              manga: foundBook.attributes[0] ? foundBook.attributes[0].name.value.substring(0, 8) : '',
              shoujo: foundBook.bookType.name.value,
              bookType: foundBook.bookType.name.value,
              puntuacion: foundBook.bookRate,
              vistas: foundBook.bookLabelViews,
              code: foundBook.bookCode,
              estado: foundBook.bookStatus.name.value,
              lanzamiento: foundBook.bookDateCreated,
              attributes: foundBook.attributes,
              contenido: foundBook.bookContentType,
              resumen: foundBook.bookDescription,
              groupAvatarImage: foundBook.groupCreated.images[0].image.path,
            };
  
            setLibroData(libroDataStructure);
          } else {
            console.log("No se encontró el libro.");
            setLibroData(null); // Asegúrate de manejar el caso donde no se encuentra el libro
          }
        })
        .catch(error => {
          console.log("Error fetching book data:", error);
          setLibroData(null); // Maneja errores y casos donde no se puede obtener el libro
        })
        .finally(() => setLoading(false));
    }
  }, [libroData, titulo]);
  useEffect(() => {
    const fetchChapterPreview = async () => {
        try {
            const token = localStorage.getItem('token');

            const requestOptions = {
                method: "GET",
                redirect: "follow",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            // Si hay un token, añadirlo al encabezado de la solicitud
            if (token) {
                requestOptions.headers["Authorization"] = `Bearer ${token}`;
            }

            const url = `${EndpointCore.getChaptersPreview}?code=${libroData?.code}`;

            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error("Error al obtener datos del capítulo");
            }

            const data = await response.json();

            setChapters(data);

        } catch (error) {
            console.error("Error:", error);
        }
    };

    if (libroData?.code) {
        fetchChapterPreview();
    }
}, [libroData?.code]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {},
    };
    fetch(EndpointCore.recommends, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let data_items = [];
        result.items.forEach((i, index) => {
          data_items.push({
            id: index + 1,
            foto: i.images[0].image.path,
            titulo: i.bookTitle,
            capitulo: "capitulo", //i.latestChapter.group.name,
            novela: i.bookType.name.value,
            time: "Hace " + i.attributes.bookHourLatest + " horas",
            manga: "Seinen", //i.attributes.name.value,
            shoujo: "Novela", //i.bookType.name.value,
            puntuacion: i.bookRate,
            vistas: i.bookLabelViews,
          });
        });
        setItemsRecommendation(data_items);
      })
      .catch((error) => console.log("error", error));
  }, []);

  if (loading) {
    return <CustomImageLoader/>; 
  }

  if (!libroData) {
    return <div>No se encontró el libro.</div>;
  }

  const { fotoCover, estado, code, lanzamiento, bookType, tituloAlternativo, autor, resumen, groupCode, groupAvatarImage } = libroData;

  const categorias = libroData?.attributes?.filter(attr => attr.type === 'Categorías');
  const etiquetas = libroData?.attributes?.filter(attr => attr.type === 'Etiquetas');

  const originalGroupCode = groupCode;

  function formatFechaLanzamiento(fecha) {
    const opciones = { day: 'numeric', month: 'long', year: 'numeric' };
    const fechaFormateada = new Date(fecha).toLocaleDateString('es-ES', opciones);
    return fechaFormateada.replace(/\sde\s/g, ' ');
  }
  const fechaFormateada = formatFechaLanzamiento(lanzamiento);

  // Función para agrupar los capítulos por número
  const groupChaptersByNumber = (chapters) => {
    return chapters.reduce((groups, chapter) => {
      if (!groups[chapter.number]) {
        groups[chapter.number] = [];
      }
      groups[chapter.number].push(chapter);
      return groups;
    }, {});
  };

  // Función para ordenar los capítulos en cada grupo
  const sortChaptersInGroups = (groupedChapters, originalGroupCode) => {
    return Object.entries(groupedChapters).map(([number, chapters]) => {
      const originalChapter = chapters.find(ch => ch.group_code === originalGroupCode);
      const otherChapters = chapters.filter(ch => ch.group_code !== originalGroupCode);
      return {
        number,
        chapters: [originalChapter, ...otherChapters].filter(ch => ch !== undefined)
      };
    });
  };

  // Agrupar los capítulos
  const groupedChapters = groupChaptersByNumber(chapters);

  // Ordenar los capítulos dentro de cada grupo
  const sortedGroupedChapters = sortChaptersInGroups(groupedChapters, originalGroupCode);

  // Verificar el resultado
  console.log('los capitulos agrupados', sortedGroupedChapters);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const avatarSrc =  groupAvatarImage || "/img/interna/libro/avatar/avatar_1.png";


  /* contenido */
  const contenido01 = `<ul>
    <li>1. Marca de Copas </li>
    <li>2. La sentencia </li>
    <li>3. Horror del reencarnado</li>
</ul>`;
  const contenido02 = `<p>
${resumen}</p>`;
  const contenido03 = `<ul class="ul_publicado">
<li>
<a href="./#"><img src="${avatarSrc}" alt="Group Avatar" /> ${autor}</a>
</li>
<li>
<a href="./#"><img src="/img/interna/libro/avatar/avatar_2.png" alt="">Amantes GB</a>
</li>
<li>
<a href="./#"><img src="/img/interna/libro/avatar/avatar_3.png" alt="">MYBLGBAO</a>
</li>
<li>
<a href="./#"><img src="/img/interna/libro/avatar/avatar_4.png" alt="">Amantes de Yaoi y Yuri Lovers</a>
</li>
</ul>`;
const contenido04 = (categorias?.length > 0) 
  ? categorias.map(categoria => `<a href="./#">${categoria.name.value}</a>`).join('\n')
  : '';

const contenido05 = (etiquetas?.length > 0) 
  ? etiquetas.map(etiqueta => `<a href="./#">${etiqueta.name.value}</a>`).join('\n')
  : '';
  // Details
  const bookDetails= `
<div class="option">
<span class="item1">Estado</span>
<span class="item2">${estado}</span>
</div>
<div class="option">
<span class="item1">Lanzamiento</span>
<span class="item2" style="display: inline-block; white-space: nowrap; width: auto;">${fechaFormateada}</span>
</div>
<div class="option">
<span class="item1">Tipo</span>
<span class="item2">${bookType}</span>
</div>
<div class="option">
<span class="item1">Demografía</span>
<span class="item2">Shoujo</span>
</div>
`;
const headContentItem = chapters
? chapters
    .map(
      (chapter, index) =>
        `<div class="ifoto">
  <img src="${chapter.profileImage}" alt="">
</div>
<div class="itext">
  <div class="titulo" data-toggle="collapse" href="#collapseItem${
    index + 1
  }" role="button" aria-expanded="true" aria-controls="collapseItem${
          index + 1
        }">
      ${chapter.name}
      <div class="btn_cbo"></div>
  </div>
  <div class="subtitulo1">Cap. ${chapter.number}</div>
  <div class="subtitulo2">${chapter.created_at}</div>
</div>`
    )
    .join("")
: "";

  console.log("headContentItem:", headContentItem);

  const contentDetailsItem = `
<div class="item">
<div class="text">
<div class="titulo">Somos Party</div>
<div class="subtitulo">11 enero, 2012</div>
</div>
<div class="button">
<a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
<a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
</div>
</div>
<div class="item">
<div class="text">
<div class="titulo">Amantes GB </div>
<div class="subtitulo">11 enero, 2012</div>
</div>
<div class="button">
<a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
<a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
</div>
</div>
<div class="item">
<div class="text">
<div class="titulo">MYAMFLV</div>
<div class="subtitulo">11 enero, 2012</div>
</div>
<div class="button">
<a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
<a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
</div>
</div>
<div class="item">
<div class="text">
<div class="titulo">Amantes del yaoi y Yuri Lovers</div>
<div class="subtitulo">11 enero, 2012</div>
</div>
<div class="button">
<a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
<a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
</div>
</div>`;

  // Details

  const items_banner = [
    {
      foto_movil: "/img/interna/libro/banner_libro_detalle.png",
      foto : fotoCover ? fotoCover : "/img/interna/libro/banner_libro_detalle.png",
      texto: `Nuestro   Libros`,
    },
  ];
  const ibanner = [
    {
      foto_movil: "/img/banner/slider-trasparente.png",
      foto: "/img/banner/slider-trasparente.png",
      texto: ``,
    },
  ];
  /* Data acordeon */
  const AcordeonData01 = [
    {
      titulo: "Nombres alternativos",
      alias: "alternativos",
      contenido: tituloAlternativo,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData02 = [
    {
      titulo: "Información: ",
      alias: "information01",
      contenido: contenido02,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData03 = [
    {
      titulo: "Publicado por:",
      alias: "publicao",
      contenido: contenido03,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData04 = [
    {
      titulo: "Categorias:",
      alias: "categorias",
      contenido: contenido04,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData05 = [
    {
      titulo: "Etiquetas:",
      alias: "etiqueta",
      contenido: contenido05,
      open: true,
      show: "show",
    },
  ];
  const AcordeonDataChapters = [
    {
      titulo: "Detalles",
      alias: "chapter",
      contenido: bookDetails,
      open: true,
      show: "show",
    },
  ];
  const AcordeonDataDetailsItem = [
    {
      titulo: headContentItem,
      alias: "detailItem",
      contenido: contentDetailsItem,
      open: true,
      show: "show",
    },
  ];
  const tituloItemBlue = `
<div class="lbro col cel_gato">
<img src="/img/interna/libro/icon/gato.png" alt="">
</div>
<div class="lbro col cel_data">
<div class="celdata_sub titulo">
WillcaftHD_23
<div class="globo_red">Lv8</div>

</div>
<div class="celdata_sub subtitulo">
<img class="icon_estrella" src="/img/interna/libro/icon/estrella.png" alt=""> 4.02 (53) Hace 2 días
</div>
</div>`;
  const contentItemBlue = `
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
a type specimen book... <a class="red" href="./#">ver más</a></p>
<div class="lbox_grupo fotos_tws oculta_movile">
    <img class="foto_99_96" src="/img/interna/libro/foto1.png" alt="">
    <img class="foto_99_96" src="/img/interna/libro/foto1.png" alt="">
</div>
<div class="lbox_grupo likes oculta_movile">
<img class="like" src="/img/interna/libro/icon/like.png" alt="">
<div class="texto">Responder <span>|</span> Reportar</div>
</div>`;
  const AcordeonDataDetailsItemBlue = [
    {
      titulo: tituloItemBlue,
      alias: "detailItem",
      contenido: contentItemBlue,
      open: true,
      show: "show",
    },
  ];

  // CONFIG CARRUSEL RECOMENDADOS
  var configRecomendados = {
    rows: 1,
    draggable: true,
    slidesToShow: 2,
    swipe: true,
  };
  if (ancho <= 900) {
    configRecomendados = {
      rows: 1,
      draggable: true,
      slidesToShow: 2,
      swipe: true,
    };
  } else {
    configRecomendados = {
      rows: 1,
      draggable: true,
      slidesToShow: 2,
      swipe: true,
    };
  }

  
  // TAB MOVIL
  const contenidoInformacion = {resumen};

  const handleReportDropdownClick = () => {
    setShowReportDropdown(!showReportDropdown);
  };
  const firstChapterCode = chapters.length > 0 ? chapters[0].chapter_code : null;
  
  const handleCardClick = (index) => {
    const item = itemsRecommendation[index];

    if (item) {
        console.log('libroData:', item); // Imprime el libroData en la consola

        // Actualiza la URL y pasa el nuevo estado
        navigate(`/libros-detalle/${item.titulo.trim().toLowerCase().replace(/\s+/g, '-')}`, { state: { libroData } });

        // Fuerza la recarga de la página
        window.location.reload();
    }
};
  console.log(firstChapterCode)
  console.log('capitulossss' , chapters)

  const closeDropdown = ()=>{
    setShowReportDropdown(false)
  }

  if (!libroData || !sortedGroupedChapters || sortedGroupedChapters.length === 0) {
    return <CustomImageLoader/>;
  }
  return (
    <div className="interna libros detail ">
      <div className="position_header position  hide_movil  ">
        <div className="container hide_movil">
          <Menu></Menu>
        </div>
        <div className="top-banner">
          <Banner items_banner={ibanner}></Banner>
        </div>
      </div>
      <div className="position_top_A position">
        <div className="container">
          <div className="pcontent">
          <ul className="header-icon-mobile-detalle-libro">
            <li className="icon1">
              <a href="libros">
              <img src="/img/interna/libro_detalle/m-prev.png" alt="back" />
              </a>
            </li>
            <li className="icon2">
              <a>
              <img src="/img/svg/newShare.svg" alt="share" className="share-report-icon" />
              </a>
              <a>
                <img src="/img/svg/StarCommet.svg" alt="star" className="share-report-icon"/>
              </a>
              <a className="reportar-movil" onClick={handleReportDropdownClick}>
                <img
                  src="/img/svg/report.svg"
                  alt="denuncia"
                  className="share-report-icon"
                />
              </a>
              <div className="reportar">
                {showReportDropdown && <ReportarDropdown user_id={userId} entity_id={libroData.code} notification_type={'BOOK'} closeDropdown={closeDropdown}/>}
              </div>
            </li>
             
          </ul>
        
            <div className="banner-simple hide_movil">
              <Banner items_banner={items_banner}></Banner>
            </div>
            <div className="cardInfo">
            <LittleBook libroData={libroData} sortedGroupedChapters={sortedGroupedChapters}  chapters= {chapters}firstChapterCode={firstChapterCode} isUserLoggedIn={isUserLoggedIn} userId={userId}></LittleBook>
            </div>
            <div className="acordeonType2 cardInfoChapters padding-right_10">
              <Acordeon
                AcordeonData={AcordeonDataChapters}
                contenidoClass="contenidoFlex"
              ></Acordeon>
            </div>
            <div className="acordeonType2 cardInfoDetails padding-right_10">
              <div className="accordion" id="accordionExample_capitulo">
                <div className="accordion-item">
                  <BannerAdssense />
                  <h2 className="accordion-header" id="headingOne">
                    <button
                     className={`accordion-button ${isExpanded ? '' : 'collapsed'}`}
                     type="button"
                     onClick={handleToggle}
                    >
                     Capítulos
                    </button>
                  </h2>
                   <div
                     id="collapseOne_capitulo"
                     className={`accordion-collapse collapse ${isExpanded ? 'show' : ''}`}
                     aria-labelledby="headingOne"
                   > 
                   <ChaptersDropdown sortedGroupedChapters={sortedGroupedChapters} libroData={libroData} />                   
                  </div>
                </div>
              </div>
              <BannerAdssense />
            </div>
            <CommentBook libroData={libroData}/>                         {/* item*/}

          </div>
          <div className="psidebar sin_padding" style={{ float: "right" }}>
            <div className="cardInfo hide_desk">
              <LittleBook libroData={libroData} isUserLoggedIn={isUserLoggedIn} sortedGroupedChapters={sortedGroupedChapters} userId={userId}></LittleBook>
            </div>
            <div className="Tabs hide_desk">
              <div className="tabs">
                <div className="icard-header">
                  <div className="icar-h4">
                    <div className="mi-titulo">{"titulo"}</div>
                  </div>
                </div>
                <div className="scroll-cabecera">
                  <div className="mi-scroll">
                    <ul className="nav nav-tabs" role="tablist">
                      <li key={"key_position01"} className="nav-item">
                        <a
                          className={`nav-link active`}
                          id={"tab_information"}
                          data-toggle="tab"
                          href={"#information"}
                          role="tab"
                          aria-controls={"information"}
                          aria-selected={"true"}
                        >
                          {"Información"}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link`}
                          id={"tab_capitulos"}
                          data-toggle="tab"
                          href={"#capitulos"}
                          role="tab"
                          aria-controls={"capitulos"}
                          aria-selected={"false"}
                        >
                          {"Capítulos"}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link`}
                          id={"tab_comentarios"}
                          data-toggle="tab"
                          href={"#comentarios"}
                          role="tab"
                          aria-controls={"comentarios"}
                          aria-selected={"false"}
                        >
                          {"Comentarios"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-content fade show active"
                  id="information"
                  dangerouslySetInnerHTML={{ __html: contenidoInformacion }}
                  role="tabpanel"
                  aria-labelledby={"tab_information"}
                ></div>
                <div
                  className="tab-content fade"
                  id="capitulos"
                  role="tabpanel"
                  aria-labelledby={"tab_capitulos"}
                >
                  <div className="acordeonType3 cardInfoDetailsItem">
                    <Acordeon AcordeonData={AcordeonDataDetailsItem}></Acordeon>
                  </div>
                </div>
                <div
                  className="tab-content fade icomentario"
                  id="comentarios"
                  role="tabpanel"
                  aria-labelledby={"tab_comentarios"}
                >
                  <div class="lbox_bold oculta_movile">Comentarios:</div>
                  <div className="acordeonType3 cardInfoDetailsItem blue">
                    <Acordeon AcordeonData={AcordeonDataDetailsItemBlue}>
                      {" "}
                    </Acordeon>
                  </div>
                </div>
              </div>
            </div>
            <div className="padding_14">
              <div className="acordeonType1">
                <Acordeon AcordeonData={AcordeonData01}></Acordeon>
              </div>
              <div className="acordeonType1">
                <Acordeon AcordeonData={AcordeonData02}></Acordeon>
              </div>
              <div className="acordeonType1">
                <Acordeon AcordeonData={AcordeonData03}></Acordeon>
              </div>
              <div className="acordeonType1 grupo_guinda">
                <Acordeon AcordeonData={AcordeonData04}></Acordeon>
              </div>
              <div className="acordeonType1 grupo_red">
                <Acordeon AcordeonData={AcordeonData05}></Acordeon>
              </div>
            </div>
            <div className="acordeonType2 cardInfoChapters padding-right_10">
              <Acordeon
                AcordeonData={AcordeonDataChapters}
                contenidoClass="contenidoFlex"
              ></Acordeon>
            </div>
            <div className="bgblue color_white icomentario">
              <div class="lbox_bold oculta_movile hide_comentario">
                Comentarios:
              </div>
              <div className="acordeonType3 cardInfoDetailsItem blue hide_comentario">
                <Acordeon AcordeonData={AcordeonDataDetailsItemBlue}></Acordeon>
              </div>
              <div className="container recomendation">
                <Carrusel
                  icarrusel={itemsRecommendation}
                  key_position={"key003"}
                  titulo={"Recomendados"}
                  config={configRecomendados}
                  onCardClick={handleCardClick}
                ></Carrusel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {ancho > 900 ? (
        <Footer />
      ) : (
        <li className="vistas">
          <div className="acciones">
            <ul>
              <li>
                <img src="img/interna/libro/icon/like.png" alt="" />
              </li>
              <li>
                <img src="img/interna/libro/icon/banderola.png" alt="" />
              </li>
              <li>
                <button type="button" className="btn btn_rosado">
                  <Link to="/libros_lectura_capitulos">"Empezar leer"</Link>
                </button>
              </li>
            </ul>
          </div>
        </li>
      )}
    </div>
  );
}
export default BookDetail;

import React, { useEffect } from "react";
import DropdownForm from "../dropdownForm/DropdownForm";
import DropdownAgrupacion from "../dropdownForm/DropdownAgrupacion";
import DropdownAttribute from "../dropdownForm/DropdownAttribute";

export default function FormUploadBook({
  formState,
  setFormState,
  categoria,
  etiquetas,
  demografia,
  statusFilter,
  languageOptions,
  typesFilter,
  agrupacion,
  bookData,
}) {
  useEffect(() => {
    if (bookData) {
      const formattedFecha = new Date(bookData.bookData.created_at).toISOString().slice(0, 16);

      setFormState((prevState) => ({
        ...prevState,
        nombreManga: bookData.bookData.title || "",
        autor: bookData.bookData.author || "",
        nombreAlternativo: bookData.bookData.alternative_title || "",
        estado: bookData.bookData.activity_status || "",
        fecha: formattedFecha || "",
        bookTypeId: bookData.bookData.bookTypeAlias || null,
        agrupacion: bookData.bookData.groupName || null,
        categoria: bookData.bookData.categoria || [],
        etiquetas: bookData.bookData.etiquetas || [],
        demografia: bookData.bookData.demografia || "",
      }));
    }
  }, [bookData]);

  const handleInputChange = (label, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const handleMultiSelectChange = (label, selectedOptions, maxLimit) => {
    if (selectedOptions.length <= maxLimit) {
      setFormState((prevState) => ({
        ...prevState,
        [label]: selectedOptions,
      }));
    } else {
      alert(`Puedes elegir hasta ${maxLimit} de ${label}`);
    }
  };

  const handleTiposDeLibroSelect = (label, option) => {
    const selectedType = typesFilter.find((type) => type.item === option);
  
    if (selectedType) {
      setFormState((prevState) => ({
        ...prevState,
        bookTypeId: selectedType.item,
      }));
    }
  };

  return (
    <div>
      <div id="tab1" className="tabcontent" style={{ display: "block" }}>
        <div className="box ">
          <div className="catalogo">
            <div className="ibillete">
              <span className="mb-0" style={{ fontSize: 14 }}>
                CREAR NUEVO TÍTULO
              </span>
              <p className="mb-5">Cuéntanos más sobre el libro</p>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="group-date historia">
                      <span>Nombre del manga</span>
                      <input
                        className="form-input"
                        type="text"
                        value={formState.nombreManga}
                        onChange={(e) =>
                          handleInputChange("nombreManga", e.target.value)
                        }
                      />
                    </div>
                    <div className="group-date historia">
                      <span>Autor(a)</span>
                      <input
                        className="form-input"
                        type="text"
                        value={formState.autor}
                        onChange={(e) =>
                          handleInputChange("autor", e.target.value)
                        }
                      />
                    </div>
                    <div className="group-date historia">
                      <span>Nombre alternativo</span>
                      <input
                        className="form-input"
                        type="text"
                        value={formState.nombreAlternativo}
                        onChange={(e) =>
                          handleInputChange("nombreAlternativo", e.target.value)
                        }
                      />
                    </div>
                    <div className="group-date historia">
                      <span>Fecha</span>
                      <input
                        className=""
                        type="datetime-local"
                        value={formState.fecha}
                        onChange={(e) =>
                          handleInputChange("fecha", e.target.value)
                        }
                      />
                    </div>
                    <div className="group-date historia">
                      <DropdownForm
                        options={demografia}
                        onSelect={(option) =>
                          handleInputChange("demografia", option)
                        }
                        label={"Demografía"}
                        initialValue={bookData ? bookData.bookData.demografia : null}
                      />
                    </div>
                    <div className="group-date historia">
                      <DropdownAttribute
                        options={etiquetas}
                        onSelect={(selectedOptions) =>
                          handleMultiSelectChange("etiquetas", selectedOptions, 6)
                        }
                        label={"Etiquetas"}
                        initialValue={bookData ? bookData.bookData.etiquetas : []}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="group-date historia">
                      <DropdownForm
                        options={languageOptions}
                        onSelect={(option) => handleInputChange("idioma", option)}
                        label={"Idioma"}
                        initialValue={bookData ? bookData.bookData.language : null}
                      />
                    </div>
                    <div className="group-date historia">
                      <DropdownAgrupacion
                        options={agrupacion}
                        onSelect={(option) =>
                          handleInputChange("agrupacion", option)
                        }
                        label={"Agrupación"}
                      />
                    </div>
                    <div className="group-date historia">
                      <DropdownForm
                        options={statusFilter}
                        onSelect={(option) => handleInputChange("estado", option)}
                        label={"Estado"}
                        initialValue={bookData ? bookData.bookData.statusTranslation : null}
                      />
                    </div>
                    <div className="group-date historia">
                      <DropdownForm
                        options={typesFilter}
                        onSelect={(option) =>
                          handleTiposDeLibroSelect("bookTypeId", option)
                        }
                        label={"Tipo de Libro"}
                        initialValue={bookData ? bookData.bookData.bookTypeAlias : null}
                      />
                    </div>
                    <div className="group-date historia">
                      <DropdownAttribute
                        options={categoria}
                        onSelect={(selectedOptions) =>
                          handleMultiSelectChange("categoria", selectedOptions, 4)
                        }
                        label={"Categoría"}
                        initialValue={bookData ? bookData.bookData.categorias : []}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

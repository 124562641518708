import './style.css';
import React from 'react';
import Registro from './Registro';
import Inicio from './Inicio';
import Buscar from './Buscar';
import Unete from './Unete';
import Quiensomos from './Quiensomos';
import Promo from "./Promo";

function Html(props){   
 const {imodal, ocultarImagen, onActiveWindowChange, goBack, setGoBack} = props;
  let  ventana = '';
        switch(imodal) {
          case 'registro':
            ventana = <Registro />;
            break           
          case 'inicio':
            ventana = <Inicio
             ocultarImagen={ocultarImagen}  
             onActiveWindowChange={onActiveWindowChange} 
             goBack={goBack}
             setGoBack={setGoBack}
             ></Inicio>;
            break          
          case 'buscar':
            ventana = <Buscar></Buscar>;
            break          
          case 'unete':
            ventana = <Unete />;
            break          
          case 'quienes-modal':
            ventana = <Quiensomos />;
            break  
          case "promo":
            ventana = <Promo />;
            break       
          default:
            ventana = 'nadax'; 
            break          
        }
        return(
            <div className='ihtmlx'>
                {ventana}
            </div>
        ); 
  
        
}
export default Html;

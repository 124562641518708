import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import {parseISO, differenceInHours, differenceInMinutes,differenceInDays} from "date-fns";
import "./CommentBox.css";
import EndpointCore from "../../pages/Global.jsx";
import useAuth from "../../hook/useAuth.js";
import StarRating from "./StarRating";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import CommentEditor from "./CommentEditor";
import ReportarDropdown from "../reportarDropdown/ReportarDropdown";

const CommentBook = ({ libroData }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [newCommentImage, setNewCommentImage] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showStickerPicker, setShowStickerPicker] = useState(false);
  const [showReplyStickerPicker, setShowReplyStickerPicker] = useState(false);
  const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);
  const [selectedReplyIndex, setSelectedReplyIndex] = useState(null); 
  const [replyText, setReplyText] = useState("");
  const [replyImage, setReplyImage] = useState([]);
  const [sortBy, setSortBy] = useState("principales");
  const [selectedEditComment, setSelectedEditComment] = useState(null); 
  const [editedContent, setEditedContent] = useState("");
  const [selectedEditReply, setSelectedEditReply] = useState(null);
  const [selectedNestedEditReply, setSelectedNestedEditReply] = useState(null);
  const [editedCommentImages, setEditCommentImages] = useState([]); 
  const [editedReplyImages, setEditReplyImages] = useState([]);
  const [editNestedReplyImages, setEditNestedReplyImages] = useState([]);
  const [rating, setRating] = useState(null);
  const [showStickerPickerId, setShowStickerPickerId] = useState(null);
  const [showReportDropdown, setShowReportDropdown] = useState(false);
  const [showReplyReportDropdown, setShowReplyReportDropdown] = useState(false);
  const [showNestedReplyReportDropdown, setShowNestedReplyReportDropdown] = useState(false);

  const commentRef = useRef(null);
  const replyRef = useRef(null);
  const nestedReplyRef = useRef(null);
  console.log ('LIBRO DATA EN COMMMENT BOOK', libroData)
  console.log('EL SELECTED COMENT INDEX', selectedCommentIndex)
  const { groupCode: groupId, code: bookId,} = libroData;
  const { auth } = useAuth();
console.log('LOSCOMENTARIOS', comments)
  const userName = auth.user_name;
  const avatarId = auth.avatar_id || 1;
  const userId = auth.id;
  const userLogedInn = localStorage.getItem("token");
 
  // Configuración de Dropzone para imágenes de comentarios
  const {
    getRootProps: getCommentRootProps,
    getInputProps: getCommentInputProps,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const files = acceptedFiles.slice(0, 2);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setNewCommentImage((prevImages) => [
            ...(prevImages || []),
            reader.result,
          ]);
        };
        reader.readAsDataURL(file);
      });
    },
  });

  // Configuración de Dropzone para imágenes de respuestas
  const { 
    getRootProps: getReplyRootProps, 
    getInputProps: getReplyInputProps 
  } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        const files = acceptedFiles.slice(0, 2);
        files.forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setReplyImage((prevImages) => [
              ...(prevImages || []),
              reader.result,
            ]);
          };
          reader.readAsDataURL(file);
        });
      },
    });

    // configuración dropzone imagenes edición de comentarios
    const {
      getRootProps: getEditCommentRootProps,
      getInputProps: getEditCommentInputProps,
    } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        const files = acceptedFiles.slice(0, 2);
        files.forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setEditCommentImages((prevImages) => [
              ...(prevImages || []),
              reader.result,
            ]);
          };
          reader.readAsDataURL(file);
        });
      },
    });
    //configuracion dropzone imagenes edicion de respuestas
    const {
      getRootProps: getEditReplyRootProps,
      getInputProps: getEditReplyInputProps,
    } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        const files = acceptedFiles.slice(0, 2);
        files.forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setEditReplyImages((prevImages) => [
              ...(prevImages || []),
              reader.result,
            ]);
          };
          reader.readAsDataURL(file);
        });
      },
    });
    // configuración de dropzone imagenes respuestas anidadas
    const {
      getRootProps: getEditNestedReplyRootProps,
      getInputProps: getEditNestedReplyInputProps,
    } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        const files = acceptedFiles.slice(0, 2); 
        files.forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setEditNestedReplyImages((prevImages) => [
              ...(prevImages || []),
              reader.result,
            ]);
          };
          reader.readAsDataURL(file);
        });
      },
    });
    useEffect(() => {
      if (selectedEditComment) {
        setEditCommentImages(selectedEditComment.images || []);
      }
    }, [selectedEditComment]);
    
    useEffect(() => {
      if (selectedEditReply) {
        setEditReplyImages(selectedEditReply.images || []);
      }
    }, [selectedEditReply]);

    useEffect(() => {
      if (selectedNestedEditReply) {
        setEditNestedReplyImages(selectedNestedEditReply.images || []);
      }
    }, [selectedNestedEditReply]);
  
    useEffect(() => {
      if (bookId) {
        const fetchBookComments = async () => {
          try {
            const response = await fetch(EndpointCore.getBookComments(bookId), {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            });
    
            if (!response.ok) {
              throw new Error("Error fetching comments");
            }
    
            const data = await response.json();
            setComments(data);
          } catch (error) {
            console.error("Error al obtener los comentarios:", error);
          }
        };
    
        fetchBookComments();
      }
    }, [bookId]);

  const submitComment = async () => {
    try {
      const response = await fetch(EndpointCore.createBookComment, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          user_name: userName,
          text: newComment,
          images: newCommentImage,
          group_id: groupId,
          book_id: bookId,
          user_avatar_id: avatarId,
          book_rating: rating,
        }),
      });

      if (response.ok) {
        const newComment = await response.json();
        setComments([...comments, newComment]);
        setNewComment("");
        setNewCommentImage([]);
        setRating(null);
      } else {
        const errorData = await response.json();
        console.error("Error al enviar el comentario:", errorData);
      }
    } catch (error) {
      console.error("Error al enviar el comentario:", error);
    }
  };
  const handleAddComment = () => {
    console.log("Valor de rating:", rating); // Verifica el valor de rating

    if (newComment.trim() !== "") {
      if (rating !== null) {
        submitComment();
      } else {
        toastr.error("Debes dejar una puntuación al hacer un comentario");
      }
    }
  };

  const handleStickerPickerClick = (editorId) => {
    setShowStickerPickerId(prevId => prevId === editorId ? null : editorId);
  };
  const handleAddReply = (commentIndex, replyIndex = null) => {
    if (replyText.trim() !== "") {
      const commentId = comments[commentIndex].comment_book_id;
      const commentUser = comments[commentIndex].user_name;
      const parentReplyId = replyIndex !== null ? comments[commentIndex].replies[replyIndex]._id : null; // Obtener el _id de la respuesta anidada
      submitReply(commentId, parentReplyId, commentUser);
    }
  };

  const handleReplyToReply = (commentIndex, replyIndex) => {
    setSelectedCommentIndex(commentIndex); // Establecer el índice del comentario principal
    setSelectedReplyIndex(replyIndex); // Establecer el índice de la respuesta anidada
  };

  const submitReply = async (commentId, parentReplyId = null, commentUser) => {
    try {
      // Preparar el cuerpo de la solicitud
      const body = {
        user_name: userName,
        text: `@${commentUser}&nbsp; ${replyText}`,
        images: replyImage,
        group_id: groupId,
        book_id: bookId,
        user_avatar_id: avatarId,
        parent_reply_id: parentReplyId, // Añadir el ID de la respuesta padre si existe
      };
  
      const response = await fetch(EndpointCore.createBookReply(commentId), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:` Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      });
  
      if (response.ok) {
        const newReply = await response.json();

        setComments((prevComments) => {
           if (parentReplyId) {
            return prevComments.map((comment) => 
              comment.comment_book_id === commentId ? newReply : comment
            );
          }  else {
            // Si es una respuesta directa al comentario, reemplaza el comentario completo
            return prevComments.map((comment) =>
              comment.comment_book_id === commentId
                ? newReply
                : comment
            );
          }
        });
        // Limpiar el estado de la respuesta
        setReplyText("");
        setReplyImage([]);
        setSelectedCommentIndex(null);
        setSelectedReplyIndex(null); // Resetear el índice de la respuesta seleccionada
      } else {
        const errorData = await response.json();
        console.error("Error al enviar la respuesta:", errorData);
      }
    } catch (error) {
      console.error("Error al enviar la respuesta:", error);
    }
  };
  const sortedComments = comments.sort((a, b) => {
    if (sortBy === "recientes") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (sortBy === "principales") {
      return 0;
    }
    return 0;
  });
  console.log("SORTED COMMENTS", sortedComments)

  const handleLike = async (commentId, replyId = null) => {
    try {
      const response = await fetch(EndpointCore.addBookCommentLike(commentId, replyId), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          commentId, 
          replyId
        }),
      });
        if (response.ok) {
        setComments(prevComments => 
          prevComments.map(comment => {
            if (comment.comment_book_id === commentId) {
              if (replyId) {
                return {
                  ...comment,
                  replies: comment.replies.map(reply => 
                    reply._id === replyId
                      ? { ...reply, likes_count: (reply.likes_count + 1 || 0) }
                      : reply
                  )
                };
              } else {
                return { ...comment, likes_count: (comment.likes_count + 1 || 0) };
              }
            }
            return comment;
          })
        );
      } else {
        console.error('Error al agregar el like');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleReport = () => {
    console.log("Report button clicked");
  };

  const getCustomTimeAgo = (updatedAt) => {
    const now = new Date();
    const parsedDate = parseISO(updatedAt);

    const daysDifference = differenceInDays(now, parsedDate);
    const hoursDifference = differenceInHours(now, parsedDate);
    const minutesDifference = differenceInMinutes(now, parsedDate);

    if (daysDifference > 0) {
      return `hace ${daysDifference} días`;
    } else if (hoursDifference > 0) {
      return `hace ${hoursDifference} horas`;
    } else {
      return `hace ${minutesDifference} minutos`;
    }
  };

 const handleEditClick = (comment) => {
    setSelectedEditComment(comment);
    setEditedContent(comment.text); 
  };
 const handleEditReply = (reply)=> {
    setSelectedEditReply(reply);
    setEditedContent(reply.text);
    setReplyImage(reply.images || []);
  } 

  const handleEditNestedReply = (nestedReply) => {
  setSelectedNestedEditReply(nestedReply);
  setEditedContent(nestedReply.text);
  setReplyImage(nestedReply.images || []);
};
  const handleCancel = () => {
    setNewComment(""); 
    setNewCommentImage([]); 
    setShowStickerPicker(false); 
    setShowEmojiPicker(false); 
  };
  const handleCancelReply = () => {
    setReplyText(""); 
    setShowReplyStickerPicker(false); 
    setShowEmojiPicker(false); 
    setSelectedCommentIndex(null);
  };
  const handleCancelEdit = () => {
    setSelectedEditComment(null); 
    setSelectedEditReply(null); 
    setSelectedNestedEditReply(null);
    setEditedContent(""); 
  }
  const handleRemoveImage = (index, isReply = false, isNestedReply = false) => {
    console.log('Removing image at index:', index, 'Is Reply:', isReply, 'Is Nested Reply:', isNestedReply);
  
    if (isNestedReply) {
      setEditNestedReplyImages((prevImages) => {
        const newImages = prevImages.filter((_, i) => i !== index);
        return newImages;
      });
    } else if (isReply) {
      setEditReplyImages((prevImages) => {
        const newImages = prevImages.filter((_, i) => i !== index);
        return newImages;
      });
    } else {
      setEditCommentImages((prevImages) => {
        const newImages = prevImages.filter((_, i) => i !== index);
        return newImages;
      });
    }
  };
 
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(EndpointCore.deleteBookComment(commentId), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error al eliminar el comentario');
      }
  
      setComments((prevComments) => prevComments.filter(comment => comment.comment_book_id !== commentId));

      console.log('Comentario eliminado exitosamente');
    } catch (error) {
      console.error('Error al eliminar el comentario:', error);
    }
  }
  const handleDeleteReply = async (commentId, replyId) => {
    try {
      const response = await fetch(EndpointCore.deleteBookReply(commentId, replyId), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error al eliminar la respuesta');
      }
  
      setComments((prevComments) =>
        prevComments.map((comment) => 
          comment.comment_book_id === commentId
            ? {
                ...comment,
                replies: comment.replies.filter((reply) => reply._id !== replyId),
              }
            : comment
        )
      );
      console.log('Respuesta marcada como eliminada');
    } catch (error) {
      console.error('Error al eliminar la respuesta:', error);
    }
  };

  const handleDeleteNestedReply = async (commentId, replyId, nestedReplyId) => {
    try {
      const response = await fetch(EndpointCore.deleteNestedBookReply(commentId, replyId, nestedReplyId), {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
    if (response.ok) {
      setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.comment_book_id === Number(commentId)
          ? {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply._id === replyId
                  ? {
                      ...reply,
                      replies: reply.replies.filter(
                        (nestedReply) => nestedReply._id !== nestedReplyId
                      ),
                    }
                  : reply
              ),
            }
          : comment
      )
    );
}else {
        const errorData = await response.json();
        console.error('Error al eliminar la respuesta anidada:', errorData);
      }
    } catch (error) {
      console.error('Error en la solicitud de eliminación:', error);
    }
  };

  const submitEditComment = async (commentId, updatedContent, updatedImages) => {
    try {
      const response = await fetch(EndpointCore.editBookComment(commentId), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization:` Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          text: updatedContent,
          images: updatedImages,
        }),
      });
  
      if (response.ok) {
        // Obtener el comentario actualizado
        const responseData = await response.json();
        const updatedComment = responseData.updatedComment || responseData; // Ajusta esto según tu API
  
        // Actualizar el estado del frontend
        setComments(prevComments => {
          return prevComments.map(comment =>
            comment.comment_book_id === updatedComment.comment_book_id ? updatedComment : comment
          );
        });
  
        // Limpiar el estado del editor
        setEditedContent(""); 
        setEditCommentImages([]);
        setSelectedEditComment(null); // Reiniciar el comentario seleccionado para edición
      } else {
        const errorData = await response.json();
        console.error('Error al editar el comentario:', errorData);
      }
    } catch (error) {
      console.error('Error en la solicitud de edición:', error);
    }
  };
  const handleSaveCommentEdit = () => {
    if (selectedEditComment) {
      submitEditComment(selectedEditComment.comment_book_id, editedContent, editedCommentImages);
    }
  };
const submitEditReply = async (commentId, replyId, updatedContent, updatedImages) => {
  try {
    const response = await fetch(EndpointCore.editBookReply(replyId), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        text: updatedContent,
        images: updatedImages,
        commentId: commentId, // Asocia la respuesta con el comentario correspondiente
      }),
    });

    if (response.ok) {
      // Obtener la respuesta actualizada
      const responseData = await response.json();
      console.log('la respuesta de edicon respuesta', responseData)
      const updatedComment = responseData.updatedComment;

      // Actualizar el estado del frontend reemplazando el comentario completo
      setComments(prevComments =>
        prevComments.map(comment =>
          comment.comment_BOOK_id === updatedComment.comment_book_id
            ? updatedComment
            : comment
        )
      );

      // Limpiar el estado del editor
      setEditedContent("");
      setEditReplyImages([]);
      setSelectedEditReply(null); // Reiniciar la respuesta seleccionada para edición
    } else {
      const errorData = await response.json();
      console.error('Error al editar la respuesta:', errorData);
    }
  } catch (error) {
    console.error('Error en la solicitud de edición:', error);
  }
};
const handleSaveReplyEdit = () => {
  if (selectedEditReply) {
    submitEditReply(selectedEditReply.comment_book_id, selectedEditReply._id, editedContent, editedReplyImages);
  }
};

const submitEditNestedReply = async (commentId, replyId, nestedReplyId, updatedContent, updatedImages) => {
  try {
    const response = await fetch(EndpointCore.editNestedBookReply(commentId, replyId, nestedReplyId), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        text: updatedContent,
        images: updatedImages,
      }),
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log('DATA EDICION NESTEDREPLY',responseData)
      const updatedComment = responseData.updatedComment || responseData; // Ajusta según tu API


    setComments(prevComments =>
  prevComments.map(comment =>
    comment.comment_id === updatedComment.comment_id
      ? updatedComment
      : comment
  )
);
      setEditedContent("");
      setEditNestedReplyImages([]);
      setSelectedNestedEditReply(null);
    } else {
      const errorData = await response.json();
      console.error('Error al editar la respuesta anidada:', errorData);
    }
  } catch (error) {
    console.error('Error en la solicitud de edición:', error);
  }
};
const handleSaveNestedReplyEdit = (commentId, replyId, nestedReplyId) => {
    submitEditNestedReply(
      commentId,
      replyId,
      nestedReplyId,
      editedContent,                       
      editNestedReplyImages                
    );
};

const handleReportDropdownClick = () => {
  setShowReportDropdown(!showReportDropdown);
};
const handleReportReplyDropdownClick = () => {
  setShowReplyReportDropdown(!showReplyReportDropdown);
};

const handleReportNestedReplyDropdownClick = ()=>{
  setShowNestedReplyReportDropdown(!showNestedReplyReportDropdown);
}
const closeDropdwon =()=>{
  setShowReportDropdown(false);
  setShowReplyReportDropdown(false);
  setShowNestedReplyReportDropdown(false);
}

  return (
    <div className="comment-box">
    <div className="comment-header">
      <div className="w-full top">
        <div className="left-a">
          {comments.reduce(
            (total, comment) => total + 1 + (comment.replies?.length || 0), 0)}{" "}
          comentarios
        </div>
        <div className="right-a">
          <span
            className={sortBy === "principales" ? "active" : ""}
            onClick={() => setSortBy("principales")}
          >
            Principales
          </span>{" "}
          |
          <span
            style={{ marginLeft: '5px' }}
            className={sortBy === "recientes" ? "active" : ""}
            onClick={() => setSortBy("recientes")}
          >
            Recientes
          </span>
        </div>
      </div>
      {!userLogedInn && (
        <div className="w-full alerta">
          <div className="alerta-gris">
            <img src="img/interna/libro_detalle/campanita.png" alt="" />
            <span>Por favor inicie sesión para dejar un comentario</span>
          </div>
        </div>
      )}
    </div>
    {userLogedInn && (
      <>
        <div className="calification-container">
          <img
            src="/img/svg/catAvatar.svg"
            alt="avatar"
            className="cat-avatar"
          />
          <span>La calificación de este capítulo es de:</span>
          <StarRating totalStars={5} rating={rating} onRatingChange={handleRatingChange} />
        </div>
        <div className="comment-form">
          <CommentEditor
            editorId="newCommentEditor"
            value={newComment}
            onChange={setNewComment}
            getRootProps={getCommentRootProps}
            getInputProps={getCommentInputProps}
            handleSubmit={handleAddComment}
            handleCancel={handleCancel}
            showStickerPickerId={showStickerPickerId}
            handleStickerPickerClick={handleStickerPickerClick}
            quillRef={commentRef}
          />
          {newCommentImage.length > 0 && (
            <div className="images-preview">
              {newCommentImage.map((image, index) => (
                <img key={index} src={image} alt={`Selected ${index}`} />
              ))}
            </div>
          )}
        </div>
      </>
    )} 
      <div className="comments-list">
        {sortedComments.map((comment, index) => (
          <div key={index} className="comment">
            <div className="comment-section">
            <div className="comment-header">
              <div style={{ display: "flex" }}>
                <img
                  src={
                    comment.user?.avatar ||
                    "/img/login/avatar-selected/avatar5.png"
                  }
                  alt="Avatar"
                  className="avatar"
                />
                <div className="comment-header-info">
                  <span className="username">
                    {comment?.user_name || "Anonymous"}
                  </span>
                  <span className="rating">
                    <div className="rating-container">
                      <img
                        src="/img/svg/icon_star_purple.svg"
                        alt="star icon"
                        className="star-icon"
                      />
                      <span className="average-rating">{comment?.book_rating || 0}</span>
                      <span className="total-comments">
                        ({comment?.likes_count || 0})
                      </span>
                    </div>
                  </span>
                </div>
                <div className="detalle-comentarios">
                {userLogedInn && userId === comment.user_id && (
                  <img
                  src="/img/svg/Edit.svg"
                  alt="Edit Icon"
                  className="edit-icon"
                  onClick={() => handleEditClick(comment)}
                  />
                )}
                  <span>{getCustomTimeAgo(comment.updated_at)}</span>
                </div>
              </div>
            </div>
            {selectedEditComment && selectedEditComment.comment_id === comment.comment_id ? (
  <>
    <div className="editor-container">
      <CommentEditor
        editorId={"editCommentEditor"}
        value={editedContent || comment.text} 
        onChange={setEditedContent}
        getRootProps={getEditCommentRootProps}
        getInputProps={getEditCommentInputProps}
        handleSubmit={handleSaveCommentEdit}
        handleCancel={handleCancelEdit}
        isEditing= {true}
        handleDelete={() => handleDeleteComment(selectedEditComment.comment_book_id)} // Pasa la función de eliminar
        showStickerPickerId={showStickerPickerId}
        handleStickerPickerClick={handleStickerPickerClick}
        quillRef={commentRef}
      />
      {/* Renderiza las imágenes subidas */}
      {editedCommentImages.length > 0 && (
        <div className="images-preview">
          {editedCommentImages.map((image, index) => (
            <div key={index} className="image-container">
              <img src={image} alt={`Uploaded ${index}`} />
              <img src={'/img/svg/trash-grey.svg'} alt='trash' className="trash-icon" onClick={() => handleRemoveImage(index)} />
            </div>
          ))}
        </div>
      )}
    </div>
  </>
) : (
  // Renderiza el comentario en modo lectura si no está en edición
  <ReactQuill
    value={comment.text}
    readOnly={true}
    theme={"bubble"}
    className="react-quill-readonly"
  />
)}
{!(selectedEditComment && selectedEditComment.comment_id === comment.comment_id) && comment.images?.length > 0 && (
  <div className="images-preview">
    {comment.images.map((image, index) => (
      <div className="comment-image-container" key={index}>
        <img src={image} alt={`Comment Image ${index}`} />
       
      </div>
    ))}
  </div>
)}

            {userLogedInn && (
              <div>
                <div className="button-group">
                <button onClick={() => handleLike(comment.comment_book_id)}>
                    <img
                      src="/img/interna/button/footer2/like.svg"
                      alt="Like icon"
                      className="button-icon"
                    />
                  </button>
                  <button
                    onClick={() => setSelectedCommentIndex(index)}
                    className="responder-reportar"
                  >
                    Responder
                  </button>
                  <span className="responder-reportar">|</span>
                  <button  className="responder-reportar" onClick={handleReportDropdownClick}>
                    Reportar
                  </button>
                </div>
                { showReportDropdown && 
                <ReportarDropdown 
                isComment={true} 
                user_id={userId} 
                entity_id={comment.comment_book_id} 
                notification_type={'BOOK_COMMENT'}
                closeDropdown={closeDropdwon}/>}
              </div>
            )}
             
            </div>
            <div className="reply-section">
  {selectedCommentIndex === index && (
      <>
      <div className="comment-form">
        <CommentEditor
          value={replyText}
          onChange={setReplyText}
          showStickerPickerId={showStickerPickerId}
          handleStickerPickerClick={handleStickerPickerClick}
          getRootProps={getReplyRootProps}
          getInputProps={getReplyInputProps}
          handleSubmit={() => {
            if (selectedReplyIndex !== null) {
              handleAddReply(index, selectedReplyIndex);
            } else {
              handleAddReply(index);
            }
          }}
          handleCancel={handleCancelReply}
          isEditing={false}
          editorId={'replyEditorId'}
          isReply={true} // Marca como respuesta
          quillRef={replyRef}

        /> 
          {replyImage.length > 0 && (
          <div className="images-preview">
            {replyImage.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Selected ${index}`}
              />
            ))}
             </div>
          )}
      </div>
    </>
  )}
  {comment.replies?.map((reply, replyIndex) => (
    <div key={replyIndex} className="reply comment-header">
      <div style={{ display: "flex" }}>
        <img
          src={reply?.avatar || "/img/login/avatar-selected/avatar1.png"}
          alt="Avatar"
          className="avatar"
        />
        <div className="comment-header-info">
          <span className="username">
            {reply?.user_name || "Anonymous"}
          </span>
          <span className="rating">
            <div className="rating-container">
              <img
                src="/img/svg/icon_star_purple.svg"
                alt="star icon"
                className="star-icon"
              />
              <span className="average-rating">4.3</span>
              <span className="total-comments">
                ({reply?.likes_count || 0})
              </span>
            </div>
          </span>
        </div>
        <div className="detalle-comentarios">
        {userLogedInn && userId === reply.user_id && (
          <img
            src="/img/svg/Edit.svg"
            alt="Edit Icon"
            className="edit-icon"
            onClick={() => handleEditReply(reply)} // Manejador de clic
          />
        )}
          <span>{getCustomTimeAgo(reply.updated_at)}</span>
        </div>
      </div>
      {selectedEditReply && selectedEditReply._id === reply._id ? (
  <div className="reply-editor">
  <CommentEditor
  editorId={"editReplyEditorId"} // Ajusta el ID del editor según sea necesario
  value={editedContent || reply.text}
  onChange={setEditedContent}
  getRootProps={getEditReplyRootProps} // Asegúrate de pasar esta función correctamente
  getInputProps={getEditReplyInputProps} // Asegúrate de pasar esta función correctamente
  handleSubmit={handleSaveReplyEdit}
  handleCancel={handleCancelEdit}
  handleDelete={() => handleDeleteReply(reply.comment_book_id, reply._id)} // Pasa la función de eliminar
  showStickerPickerId={showStickerPickerId}// Ajusta el ID según sea necesario
  handleStickerPickerClick={handleStickerPickerClick} // Pasa la función para alternar el sticker picker
  images={editedReplyImages} // Imágenes en la edición de respuesta
  onRemoveImage={handleRemoveImage} 
  isReply={true}  
  isEditing={true}      
  quillRef={replyRef}
/>
 {editedReplyImages?.length > 0 && (
  <div className="images-preview">
    {editedReplyImages.map((image, index) => (
      <div className="comment-image-container" key={index}>
        <img src={image} alt={`Reply Image ${index}`} />
        <img src={'/img/svg/trash-grey.svg'} alt='trash' className="trash-icon"
        onClick={()=>handleRemoveImage(index, true)} />
      </div>
    ))}
  </div>
)}
</div>
)  : (
        <>
          <ReactQuill
            value={reply.text}
            readOnly={true}
            theme={"bubble"}
            className="react-quill-readonly"
          />
          {(!selectedEditReply || selectedEditReply._id !== reply._id) && reply.images?.length > 0 && (
            <div className="images-preview">
              {reply.images.map((image, index) => (
                <div className="comment-image-container" key={index}>
                  <img src={image} alt={`Reply Image ${index}`} />
                </div>
              ))}
            </div>
          )}
        </>
      )}
     {userLogedInn && (
      <div>
      <div className="button-group">
        <button onClick={() => handleLike(index, reply._id)}>
          <img
            src="/img/interna/button/footer2/like.svg"
            alt="Like icon"
            className="button-icon"
          />
        </button>
        <button
         onClick={() => handleReplyToReply(index, replyIndex)}          
         className="responder-reportar"
        >
          Responder 
        </button>
        <span className="responder-reportar">|</span>
        <button
          onClick={handleReportReplyDropdownClick}
          className="responder-reportar"
        >
          Reportar
        </button>
      </div>
       { showReplyReportDropdown && 
        <ReportarDropdown 
        isComment={true} 
        user_id={userId} 
        entity_id={reply._id} 
        comment_id={reply.comment_book_id}
        notification_type={'BOOK_COMMENT'}
        closeDropdown={closeDropdwon}/>}
        </div>
     )}
 {/* Renderiza respuestas anidadas si existen */}
{reply.replies?.length > 0 && (
      <div className="nested-replies">
        {reply.replies.map((nestedReply, nestedReplyIndex) => (
          <div key={nestedReplyIndex} className="nested-reply comment-header">
            <div style={{ display: "flex" }}>
              <img
                src={nestedReply?.avatar || "/img/login/avatar-selected/avatar1.png"}
                alt="Avatar"
                className="avatar"
              />
              <div className="comment-header-info">
                <span className="username">
                  {nestedReply?.user_name || "Anonymous"}
                </span>
                <span className="rating">
                  <div className="rating-container">
                    <img
                      src="/img/svg/icon_star_purple.svg"
                      alt="star icon"
                      className="star-icon"
                    />
                    <span className="average-rating">{nestedReply?.book_rating || 0}</span>
                    <span className="total-comments">
                      ({nestedReply?.likes_count || 0})
                    </span>
                  </div>
                </span>
              </div>
              <div className="detalle-comentarios">
              {userLogedInn && userId === nestedReply.user_id && (
              <img
                    src="/img/svg/Edit.svg"
                    alt="Edit Icon"
                    className="edit-icon"
                    onClick={() => handleEditNestedReply(nestedReply)} 
                  />
              )}
                <span>{getCustomTimeAgo(nestedReply .updated_at)}</span>
              </div>
            </div>

        {selectedNestedEditReply && selectedNestedEditReply._id === nestedReply._id ? (
        <div className="editor-container">
        <CommentEditor
          value={editedContent}
          onChange={setEditedContent}
          showStickerPickerId={showStickerPickerId} // Asegúrate de pasar el estado correcto
          handleStickerPickerClick={handleStickerPickerClick} // Pasa la función directamente
          handleSubmit={() => handleSaveNestedReplyEdit(nestedReply.comment_book_id, reply._id, nestedReply._id)}
          handleCancel={handleCancelEdit}
          handleDelete={() => handleDeleteNestedReply(nestedReply.comment_book_id, reply._id, nestedReply._id)}
          editorId={"nestedReplyEditorId"}
          isReply={true}
          isEditing={true}
          getRootProps={getEditNestedReplyRootProps}
          getInputProps={getEditNestedReplyInputProps}
          editNestedReplyImages={editNestedReplyImages}
          handleRemoveImage={handleRemoveImage}
          quillRef={nestedReplyRef}
          />
        
        {editNestedReplyImages?.length > 0 && (
          <div className="images-preview">
            {editNestedReplyImages.map((image, index) => (
              <div className="comment-image-container" key={index}>
                <img src={image} alt={`Reply Image ${index}`} />
                <img src={'/img/svg/trash-grey.svg'} alt='trash' className="trash-icon" onClick={() => handleRemoveImage(index, false, true)} />
              </div>
            ))}
          </div>
        )}
      </div>
    ) :  (
            <>
            <ReactQuill
              value={nestedReply.text}
              readOnly={true}
              theme={"bubble"}
              className="react-quill-readonly"
            />

            {nestedReply.images?.length > 0 && (
              <div className="images-preview">
                {nestedReply.images.map((image, index) => (
                  <div key={index} className="comment-image-container">
                    <img src={image} alt={`Nested Reply Image ${index}`} />
                  </div>
                ))}
              </div>

            )}
            </>
           
            )
          } 
          {userLogedInn && (
      <div>
      <div className="button-group">
        <button onClick={() => handleLike(index, reply._id)}>
          <img
            src="/img/interna/button/footer2/like.svg"
            alt="Like icon"
            className="button-icon"
          />
        </button>
       
        <span className="responder-reportar">|</span>
        <button
          onClick={handleReportNestedReplyDropdownClick}
          className="responder-reportar"
        >
          Reportar
        </button>
      </div>
         { showNestedReplyReportDropdown && 
          <ReportarDropdown 
          isComment={true} 
          user_id={userId} 
          entity_id={nestedReply._id} 
          comment_id={nestedReply.comment_book_id}
          reply_id={reply._id}
          notification_type={'BOOK_COMMENT'}
          closeDropdown={closeDropdwon}/>}
          </div>
      )}
        
          </div>
        ))}
      </div>
    )}
    </div>
  ))}
</div>

</div>
        ))}
      </div>
       
    </div>
  );
};

export default CommentBook;
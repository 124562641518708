import React, { useEffect, useState } from 'react';
import Footer from "../components/footer/Footer";
import MenuLeft from "../components/menu-left";
import FormUploadBook from "../components/formUploadBook";
import Button from "../components/button/Boton";
import MyDropzone from "../components/drop-zone/MyDropZone";
import Global from "./Global";
import toastr from 'toastr';
import CustomImageLoader from '../components/CustomLoader/CustomImageLoader';
import EndpointCore from '../pages/Global';
import ContenidoOriginalModal from '../components/contenido-original-modal/ContenidoOriginalModal';

const readFileAsBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export default function UploadBook() {
  const [next, setNext] = useState(false);
  const [error, setError] = useState(null);
  const [categoria, setCategoria] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [demografia, setDemografia] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [typesFilter, setTypesFilter] = useState([]);
  const [agrupacion, setAgrupacion] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [images, setImages] = useState({ image1: null, image2: null });
  const [profileImageSrc, setProfileImageSrc] = useState("img/interna/profile/avatar.png");
  const [coverImageSrc, setCoverImageSrc] = useState("img/upload-banner.png");
  const [formState, setFormState] = useState({
    nombreManga: "",
    idioma: "",
    autor: "",
    agrupacion: "",
    nombreAlternativo: "",
    estado: "",
    demografia: "",
    categoria: "",
    etiquetas: "",
    bookContentType: "",
    descripcion: "",
    imagenPerfil: "",
    imagenPerfilName: "",
    imagenPortada: "",
    imagenPortadaName: "",
    fecha: "",
    bookTypeId: "",
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
 
  const handleImageChange = (event, imageNumber) => {
    const file = event.target.files[0];
  
    // Actualizar el estado con la imagen seleccionada
    setImages((prevImages) => ({
      ...prevImages,
      [imageNumber]: file,
    }));
  
    // Mostrar la imagen seleccionada en la interfaz de usuario
    if (imageNumber === 'image1') {
      setProfileImageSrc(URL.createObjectURL(file));
    } else if (imageNumber === 'image2') {
      setCoverImageSrc(URL.createObjectURL(file));
    }
  };
  
  const fetchAttributes = async () => {
    try {
      const response = await fetch('https://dmangas-core.jmasoluciones.pw/public/api/core/attributes?limitPage=72&orderByColumn=lastestUpdated&orderDir=DESC&attributeType=null');
      const data = await response.json();
      return data;     
    } catch (error) {
      throw new Error('Error fetching attributes: ' + error.message);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await fetch('https://dmangas-core.jmasoluciones.pw/api/core/book-types?limitPage=100');
      const data = await response.json();

      return data;
    } catch (error) {
      throw new Error('Error fetching types: ' + error.message);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await fetch('https://dmangas-core.jmasoluciones.pw/api/core/book-activity-status?limitPage=100');
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error fetching status: ' + error.message);
    }
  };

  const loadDataFromLocalStorage = async () => {
    let attributeData = JSON.parse(localStorage.getItem('attributesData'));
    let typesData = JSON.parse(localStorage.getItem('bookTypeData'));
    let statusData = JSON.parse(localStorage.getItem('bookActivityStatusData'));

    if (!attributeData) {
      attributeData = await fetchAttributes();
      localStorage.setItem('attributesData', JSON.stringify(attributeData));
    }

    if (!typesData) {
      typesData = await fetchTypes();
      localStorage.setItem('bookTypeData', JSON.stringify(typesData));
    }

    if (!statusData) {
      statusData = await fetchStatus();
      localStorage.setItem('bookActivityStatusData', JSON.stringify(statusData));
    }

   
    return { attributeData, typesData, statusData};
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { attributeData, typesData, statusData } = await loadDataFromLocalStorage();
        const statusFilterData = statusData?.items.map((item) => ({ item: item.name.value })) || [];
        const typesFilterData = typesData?.items.map((item, index) => ({  item: item.alias, index : index + 1 })) || [];
        const etiquetasFilterData = attributeData?.items.filter(item => item.type.name === "Etiquetas") || [];
        const etiquetasData = etiquetasFilterData.map((item) => ({ 
          value: item.attributeAlias, // Utilizar attributeAlias como valor del dropdown
          label: item.attributeName.value // Mostrar attributeName.value en el dropdown
        }));        
        const categoriaFilterData = attributeData?.items.filter(item => item.type.name === "Categorías") || [];
        const categoriaData = categoriaFilterData.map((item) => ({ 
        value: item.attributeAlias, // Utilizar attributeAlias como valor del dropdown
        label: item.attributeName.value // Mostrar attributeName.value en el dropdown
        }));        
        const demografiaFilterData = attributeData?.items.filter(item => item.type.name === "Demografía") || [];
        const demografiaData = demografiaFilterData.map((item) => ({ item: item.attributeAlias}));
        const languageOptionsData = [...new Set(attributeData?.items.map(item => item.attributeName.languageCode))]
        .map(languageCode => ({ item: languageCode }));
        setStatusFilter(statusFilterData);
        setCategoria(categoriaData);
        setEtiquetas(etiquetasData);
        setDemografia(demografiaData);
        setLanguageOptions(languageOptionsData);
        setTypesFilter(typesFilterData);

      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();

  }, []);

  useEffect(() => {
    const fetchAgrupacion = async () => {
      try {
        const jwt = localStorage.getItem('token');
    
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}` 
        };
    
        const response = await fetch(EndpointCore.getGroupsByMemberCode, { headers });
        const agrupacionData = await response.json();
        const agrupacionFilterData = agrupacionData.groups.map((item) => ( {id: item.id, name: item.name} )) ; // Aquí se accede a agrupacionData.groups
        setAgrupacion(agrupacionFilterData);

        return(agrupacionData);
      } catch (error) {
        throw new Error('Error fetching groups: ' + error.message);
      }
    };
    fetchAgrupacion()

  }, []);

  
  const handleUploadBook = async () => {
    try {
      setLoading(true)
      if (!images.image1 || !images.image2) {
      toastr.error('Debe seleccionar ambas imágenes');
      return;
    }


      if (!formState.nombreManga || !formState.idioma || !formState.autor || !formState.agrupacion || !formState.estado || !formState.demografia || !formState.categoria || !formState.etiquetas || !formState.bookContentType || !formState.descripcion ) {
        toastr.error('Todos los campos son obligatorios');
        return;
      }

      const imagenPerfilBase64 = await readFileAsBase64(images.image1);
      const imagenPortadaBase64 = await readFileAsBase64(images.image2);
  
      const newFormState = {
        ...formState,
        imagenPerfil: imagenPerfilBase64,
        imagenPortada: imagenPortadaBase64,
        imagenPerfilName: images.image1.name,
        imagenPortadaName: images.image2.name,
      };
  
      setFormState(newFormState);

      const response = await fetch(Global.createBook, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newFormState),
      });
      if (response.ok) {
        const data = await response.json();
        toastr.success('Libro creado exitosamente');
        console.log('Libro creado con éxito:', data);
      } else {
        const errorData = await response.json();
        toastr.error(errorData.message);
        console.error('Error al crear el libro:', errorData.message);
      }
    } catch (error) {
      toastr.error('Error al procesar la solicitud');
      console.error('Error al procesar la solicitud:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (value) => {
    const backendValueMap = {
      "Dominio Público (traducción)": "PUBLIC",
      "Contenido Original (Con derechos de Autor)": "ORIGINAL_CONTENT",
    };
  
    const backendValue = backendValueMap[value] || "";
  
    setFormState((prevFormState) => ({
      ...prevFormState,
      bookContentType: prevFormState.bookContentType === backendValue ? "" : backendValue,
    }));
  };

  const openModal = () => {
    setShowModal(true);
  };

  
  const handleContinueButtonClick = () => {
    if (!formState.nombreManga || !formState.idioma || !formState.autor || !formState.agrupacion || !formState.estado || !formState.demografia || !formState.categoria || !formState.etiquetas || !formState.bookContentType || !formState.descripcion ) {
      toastr.error('Todos los campos son obligatorios');
    } else {
      setNext(true);
    }
  };
  console.log(formState)
  return (
    <div className="interna comunidad_solicitudes">
      <div className="interna libro comunidad">
        <div className="position_header position ">
          <div className="seccion_interna">
            <div className="ui_container ui_container--946">
              <MenuLeft />
              <div className="col01 w-71 contenido">
                <div className="box header" style={{ height: 32, marginTop: 20 }}>
                  <span>Crear Libro</span>
                </div>
                {!next ? (
                  <div className="box body d-flex flex-column">
                    <div className="box grid-tab">
                      <FormUploadBook 
                        categoria={categoria} 
                        etiquetas={etiquetas} 
                        demografia={demografia} 
                        statusFilter={statusFilter} 
                        languageOptions={languageOptions}
                        formState={formState}
                        setFormState={setFormState}
                        typesFilter={typesFilter}
                        agrupacion ={agrupacion}
                      />
                    </div>
                     <div className={`form-check`}>
                       <input
                       className="form-check-input"
                       type="checkbox"
                       value="Dominio Público (traducción)"
                       checked={formState.bookContentType === "PUBLIC"}
                       onChange={() => handleCheckboxChange("Dominio Público (traducción)")}
                       />
                      <span>Dominio Público (traducción)</span>
                     </div>
                     <div className={`form-check`}>
                       <input
                       className="form-check-input"
                       type="checkbox"
                       value="Contenido Original (Con derechos de Autor)"
                       checked={formState.bookContentType === "ORIGINAL_CONTENT"}
                       onChange={() => handleCheckboxChange("Contenido Original (Con derechos de Autor)")}
                       onClick={openModal}
                      />
                      <span>Contenido Original (Con derechos de Autor)</span>
                     </div>
                    <li className="ibillete px-3 max-width-full-important">
                      <div
                        className="group-date historia "
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #5F36DA",
                          boxShadow: "0px 1px 1px rgba(51, 154, 240, 0.1)",
                          borderRadius: "8px",
                          maxWidth: "100%",
                          height: "108px",
                          padding: "5px 20px",
                          display: "inline-block",
                          marginRight: "30px",
                          width: "100%",
                          marginBlock: 0,
                        }}
                      >
                        <span>Descripción</span>
                        <textarea
                          className="w-100"
                          rows={3}
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              descripcion: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </li>
                    <div className={`form-check`}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                      />
                      <span>He leído y acepto los términos y condiciones</span>
                    </div>
                    <div className="w-full d-flex justify-content-center my-5">
                      <Button title="continuar" onClick={handleContinueButtonClick}  />
                    </div>
                  </div>
                ) : (
                  <ul className="catalogo">
                    <li className="ibillete">
                      <span>APARIENCIA</span>
                      <div className="group-date fila no-borde">
                        <div className="apariencia">
                          <span>APARIENCIA</span>
                          <div className="group-date fila no-borde">
                            <span>Dar una buena imagen es importante:</span>
                            <div className="r_item">
                             <MyDropzone
                               handleImageChange={handleImageChange}
                               imageNumber="image1"
                               imageSrc={profileImageSrc}
                              />                                         
                               <div className="inputs-perfil">
                                <strong className="imagen-titulo">Imagen de perfil</strong>
                                <span className="imagen-min">(Recomendado 800X80px)</span>
                                <label className="btn-file-upload btn1">
                                  Seleccionar imagen
                                   <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={(event) => handleImageChange(event, 'image1')}
                                   />
                                </label>                             
                             </div>
                          </div>
                            <div className="portada-section">
                              <strong >Imagen de portada</strong>
                              <span className="man">(Recomendado 800X80px)</span>
                              <div>
                                <MyDropzone
                                  handleImageChange={handleImageChange}
                                  imageNumber="image2"
                                  imageSrc={coverImageSrc}
                                />
                                <label className="btn-file-upload btn2" style={{ margin:" 0 auto", width: "45%" }}>
                                  Seleccionar imagen
                                  <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={(event) => handleImageChange(event, 'image2')}
                                  />
                                </label>
                                {loading && <CustomImageLoader/>}
                              </div>
                              <div className="mt-5">
                                <Button title={"Subir libro"} type="button"  onClick={handleUploadBook}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </li>
                    </ul>    
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContenidoOriginalModal show={showModal} setShow={setShowModal} />
        <Footer />
      </div>
  );
}

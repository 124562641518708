import React, { useEffect, useState } from 'react';
  import GoogleLogin from './GoogleLogin';
  import { GoogleOAuthProvider } from '@react-oauth/google';
  import "./style.css"
  import Registro from './Registro';
  import Unete from './Unete';

  function Inicio({ ocultarImagen = false, onActiveWindowChange, goBack, setGoBack}) {  
    const googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
    const [activeWindow, setActiveWindow] = useState('inicio');
    const [googleLoginInfo, setGoogleLoginInfo] = useState(null);
    
    function cambioVentana(ventana) {
      setActiveWindow(ventana);

     if (onActiveWindowChange) {
      onActiveWindowChange(ventana);
    }    
  }

  useEffect(() => {
    if (goBack) {
      console.log('Regresando a la ventana de inicio');
      console.log(goBack)
      cambioVentana('inicio')
      setGoBack(false)
    }
  }, [goBack]);

  const handleCloseSesion = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };
  
    return (
      <div className='html'>
        <ul>
          <li>
          {ocultarImagen ? null : (
              <img loading="lazy" src="/img/login/cover/bg_login.jpg" alt="" />
            )}
          </li>
          <li className='white-cuadrante'>
            <ul id="ul-inicio" className={`info-login ${activeWindow === 'inicio' ? '' : 'hideVentana'}`}>
              {/* Contenido de la ventana "inicio" */}
              {ocultarImagen ? null : (
              <>
                <li><span>Bienvenidos a D’MANGAS</span></li>
                  <li>
                  <span>Únete y disfruta las mejores historias en español</span>
                </li>
                </>
              )}
              <li>
              <GoogleOAuthProvider clientId={String(googleAuthClientId)}>
                      <GoogleLogin setGoogleLoginInfo={setGoogleLoginInfo} cambioVentana={cambioVentana} buttonText={'Únete con Google'} />
                    </GoogleOAuthProvider>
              </li>
              <li> <a ><img src="/img/svg/icon_facebook.svg" alt="" /> <span>Únete con Facebook</span></a></li> 
              <li>
                <div className='spinner-login'>
                  <a   onClick={() => cambioVentana('unete')}>
                    <div className='icon-wrapper'> <img src="/img/svg/icon_message.svg" alt="" /></div> <span>Únete con tu correo</span>
                  </a>
                </div>
              </li>
              <li><span>¿Ya tienes una cuenta?</span></li>
              <li><span><a  onClick={() => cambioVentana('registro')}>Ingresa ahora</a></span></li>
            </ul> 
            <ul id="ul-registro" className={`info-login ${activeWindow === 'registro' ? '' : 'hideVentana'}`}>
              <Registro cambioVentanaRegistro={cambioVentana} />
            </ul>
            <ul id="ul-unete" className={`info-login ${activeWindow === 'unete' ? '' : 'hideVentana'}`}>
            <Unete cambioVentana={cambioVentana} userInfo={googleLoginInfo} />
          </ul> 
          </li>
        </ul>
      </div>
    );
  }

  export default Inicio; 

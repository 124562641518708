import "../assets/css/App.css";
import "../assets/css/interna.css";
import "../assets/css/movil.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/menu/Menu";
import Banner from "../components/banner/Banner";
import Footer from "../components/footer/Footer";
import Acordeon from "../components/acordeon/Acordeon";
import Carrusel from "../components/carrusel/Carrusele";
import LittleBookEdit from "../components/little-book/LittleBookEdit";
import EndpointCore from "./Global";
import toastr from "toastr";
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import DropdownAgrupacion from "../components/dropdownForm/DropdownAgrupacion";


function BookUploadChapter() {
  const location = useLocation();
  const navigate = useNavigate();
  const { libroData, chapters } = location.state;
  console.log('LibroData desde BookUPloadChapter',libroData)
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAgrupacion, setSelectedAgrupacion] = useState(null);
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [contentUploaded, setContentUploaded] = useState(false);
  const [chapterProfileImage, setChapterProfileImage] = useState(false);
  const [chapterData, setChapterData] = useState({
    volumeNumber: "",
    chapterNumber: "",
    chapterName: "",
    content: "", // carga de capítulo con editor html
    file: "", // carga de capítulo en pdf
    images: [], // carga de capítulo en imágenes
    chapterProfileImage: "",
  });
  const [isEditorDisabled, setIsEditorDisabled] = useState(false);
  const [isFileDisabled, setIsFileDisabled] = useState(false);
  const [isNovel, setIsNovel] = useState(false);
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [groupsData, setGroupsData] = useState([]);
  const [newGroupId, setNewGroupId] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  console.log('groupsData', groupsData)
  console.log('es novel?', isNovel)
  useEffect(() => {
    setIsNovel(libroData.book_type_id === 8 || libroData.bookType === "Novela");
  }, [libroData.book_type_id, libroData.bookType]);
  
  useEffect(()=>{
    setIsNewGroup(!libroData.group_created_id)
  })
  

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const userToken = localStorage.getItem('token');
        const response = await fetch(`${EndpointCore.getGroupWithImages}`, {
          method: 'GET',
          headers: {
            'Authorization':` Bearer ${userToken}`,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Se encontró una agrupación, actualizar el estado
            const groupInfo = data.data;
            setGroupsData(groupInfo);
          } else {
            console.log('No hay agrupación para el autor actual');
          }
        }
      } catch (error) {
        console.error('Error al obtener información de la agrupación:', error);
      }
    };

    if (isNewGroup) {
      fetchGroupData();
    }
  }, [isNewGroup]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setChapterData({ ...chapterData, [name]: value });
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setChapterData({
      ...chapterData,
      [name]: value,
      isNewChapter: false, // Aseguramos que no se esté agregando un nuevo capítulo cuando se ingresa un número
    });
  };

   const handleSelect = (chapterNumber) => {
    if (chapterNumber === 'new') {
      setIsEditable(true);
      setChapterData((prevData) => ({
        ...prevData,
        chapterNumber: '',
        chapterName: '',
      }));
    } else {
      const selectedChapter = chapters.find((chapter) => chapter.number === chapterNumber);
      setChapterData((prevData) => ({
        ...prevData,
        chapterNumber: selectedChapter.number,
        chapterName: selectedChapter.name,
        volumeNumber: selectedChapter.volume
      }));
      setIsEditable(false);
    }
    setIsDropdownVisible(false);
  };

  const handleFocus = () => {
    setIsDropdownVisible(true);
  };

  const handleBlur = () => {
    // Delay the hide dropdown to allow click event to register
    setTimeout(() => {
      setIsDropdownVisible(false);
    }, 200);
  };


  const handleContentChange = (value) => {
    setChapterData({ ...chapterData, content: value });
    setIsFileDisabled(true); 
  };
  const handleImageChange = (file, option) => {
    console.log('Archivo seleccionado:', file);

    if (file instanceof FileList) {
        const fileList = Array.from(file);
        const imageFiles = fileList.filter((f) => f.type.startsWith('image/'));
        const imageArray = imageFiles.map((imageFile) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
            reader.onload = () => {
                const base64Image = reader.result;
                setChapterData((prevChapterData) => ({
                    ...prevChapterData,
                    images: [
                        ...prevChapterData.images,
                        { name: imageFile.name, data: base64Image }, // Agregar el nombre del archivo al objeto de imagen
                    ],
                }));
            };
        });
    } else {
        if (file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64Image = reader.result;
                setChapterData((prevChapterData) => ({
                    ...prevChapterData,
                    images: [
                        ...prevChapterData.images,
                        { name: file.name, data: base64Image }, // Agregar el nombre del archivo al objeto de imagen
                    ],
                }));
            };
        }
    }

    setSelectedOption(option);
    setFile(file);
    setFileUploaded(true);
    setIsEditorDisabled(true);
};

const handleFileChange = (file, option) => {
  console.log('Archivo seleccionado:', file); 

  if (file instanceof FileList) {
    file = file[0]; 
  }

  if (fileUploaded || contentUploaded) {
    toastr.error("Solo puedes subir un tipo de archivo por capítulo");
  } else {
    setSelectedOption(option);
    setFile(file);
    setFileUploaded(true);
  }
  setIsEditorDisabled(true); 
};const handleChapterProfileImage = (file) => {
  // Verificar si se seleccionó un archivo
  if (!file) {
    return;
  }

  // Crear un lector de archivos
  const reader = new FileReader();

  // Configurar el evento onload para el lector de archivos
  reader.onload = () => {
    // Obtener la URL base64 de la imagen
    const base64Image = reader.result;

    // Actualizar el estado del perfil de imagen del capítulo
    setChapterProfileImage(base64Image);

    // Actualizar el estado de los datos del capítulo
    setChapterData((prevChapterData) => ({
      ...prevChapterData,
      chapterProfileImage: base64Image,
    }));
  };

  // Leer el archivo como una URL base64
  reader.readAsDataURL(file);
};


const handleSubmit = () => {
  if ((!file && !chapterData.content) || !chapterData.chapterName || !chapterData.chapterNumber) {
    toastr.error("Por favor, completa todos los campos necesarios");
    return;
  }

  // Verificar si isNovel es true y volumeNumber está vacío
  if (isNovel && !chapterData.volumeNumber) {
    toastr.error("Por favor, completa el campo de número de volumen");
    return;
  }

  if (file && chapterData.content) {
    toastr.error("Por favor, elige solo una opción para subir capítulos");
    return;
  }

  // Forzar una actualización del estado para asegurarse de que selectedOption esté disponible
  setTimeout(() => {
    let chapterPayload;

    if (file && file.type) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64File = reader.result;
        const payload = {
          ...chapterData,
          file: base64File,
          fileName: file.name,
          isNewGroup: isNewGroup,
        };
        if (isNewGroup) {
          chapterPayload = {
            ...payload,
            bookCode: libroData.code,
            group_created_id: selectedAgrupacion,
          };
        } else {
          chapterPayload = {
            ...payload,
            id: libroData.id,
            group_created_id: libroData.group_created_id,
          };
        }

        console.log(chapterPayload);
        sendChapterToBackend(chapterPayload);
      };
    } else {
      const payload = {
        ...chapterData,
        content: chapterData.content,
        isNewGroup: isNewGroup,
      };

      if (isNewGroup) {
        chapterPayload = {
          ...payload,
          bookCode: libroData.code,
          group_created_id: selectedAgrupacion,
        };
      } else {
        chapterPayload = {
          ...payload,
          id: libroData.id,
          group_created_id: libroData.group_created_id,
        };
      }
      console.log(chapterPayload);
      sendChapterToBackend(chapterPayload);
    }
  }, 0); // La llamada a setTimeout con un tiempo de espera de 0 forzará una actualización del estado
};
const sendChapterToBackend = (chapterPayload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", 
    },
    body: JSON.stringify({chapterPayload}), 
  };

  fetch(EndpointCore.uploadChapter, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then(data => {
        throw new Error(data.error || "Error al enviar el capítulo");
      });
    }
  })
    .then(data => {
      console.log("Respuesta del servidor:", data);
      toastr.success("Capítulo subido exitosamente");
      setTimeout(() => {
        const redirectPath = isNewGroup ? `/libros-detalle` : `/libros-editar/${libroData.id}`;
        navigate(redirectPath, { state: { libroData } });}, 2000);
    })
    .catch(error => {
      console.error("Error:", error);
      toastr.error(error);
    });
};

  console.log('agrupacion seleccionada', selectedAgrupacion)

  /* contenido */
  const contenido01 = `<ul>
                            <li>1. Marca de Copas </li>
                            <li>2. La sentencia </li>
                            <li>3. Horror del reencarnado</li>
                        </ul>`;
  const contenido02 = `<p>
                            Qin Tian fue llevado a una isla desierta debido a un naufragio. Todo lo que tiene equipado es un sistema de supervivencia y una navaja. Tiene que empezar de cero. Construyendo un campamento, haciendo fuego y pescando, tiene que hacer todo lo posible para
                            sobrevivir. ¡Afortunadamente, hay muchas bellezas que lo acompañan! Diosa de la escuela, hermana rubia, loli violenta. Qin Tian fue llevado a una isla desierta debido a un naufragio. Construyendo un campamento, haciendo
                            fuego y pescando, tiene que hacer todo lo posible para sobrevivir ¡Afortunadamente, hay muchas bellezas que lo acompañan!
                        </p>`;
  const contenido03 = `<ul class="ul_publicado">
    <li>
        <a href="./#"><img src="/img/interna/libro/avatar/avatar_1.png" alt=""> Somos party</a>
    </li>
    <li>
        <a href="./#"><img src="/img/interna/libro/avatar/avatar_2.png" alt="">Amantes GB</a>
    </li>
    <li>
        <a href="./#"><img src="/img/interna/libro/avatar/avatar_3.png" alt="">MYBLGBAO</a>
    </li>
    <li>
        <a href="./#"><img src="/img/interna/libro/avatar/avatar_4.png" alt="">Amantes de Yaoi y Yuri Lovers</a>
    </li>
</ul>`;
  const contenido04 = `
<a href="./#">Comedia</a>
<a href="./#">Aventura</a>
<a href="./#">Manga</a>
<a href="./#">Amor de la infancia</a>
<a href="./#">Venganza</a>
<a href="./#">Anime</a>
`;
  const contenido05 = `<a href="./#">Comedia</a>
<a href="./#">Aventura</a>
<a href="./#">Manga</a>
<a href="./#">Amor de la infancia</a>
<a href="./#">Venganza</a>
<a href="./#">Anime</a>`;
  // Details

  // Chapters
  const contentChapters = `
    <div class="option">
        <span class="item1">Estado</span>
        <span class="item2">Emitido</span>
    </div>
    <div class="option">
        <span class="item1">Lanzamiento</span>
        <span class="item2">11 enero, 2012</span>
    </div>
    <div class="option">
        <span class="item1">Tipo</span>
        <span class="item2">Manga</span>
    </div>
    <div class="option">
        <span class="item1">Demografía</span>
        <span class="item2">Shoujo</span>
    </div>
`;

  const headContentItem = `
<div class="ifoto">
    <a href="libros_lectura_capitulo"><img src="/img/interna/libro/foto1.png" alt=""></a>
</div>
<div class="itext">
    <div class="titulo" data-toggle="collapse" href="#collapseItema1" role="button" aria-expanded="true" aria-controls="collapseItem1">El inicio del héroe caído
        <div class="btn_cbo"></div>
    </div>
    <div class="subtitulo1">Cap. 01</div>
    <div class="subtitulo2">11 enero, 2012</div>
</div>`;
  const contentDetailsItem = `
<div class="item">
    <div class="text">
        <div class="titulo">Somos Party</div>
        <div class="subtitulo">11 enero, 2012</div>
    </div>
    <div class="button">
        <a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
        <a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
    </div>
</div>
<div class="item">
    <div class="text">
        <div class="titulo">Amantes GB </div>
        <div class="subtitulo">11 enero, 2012</div>
    </div>
    <div class="button">
        <a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
        <a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
    </div>
</div>
<div class="item">
    <div class="text">
        <div class="titulo">MYAMFLV</div>
        <div class="subtitulo">11 enero, 2012</div>
    </div>
    <div class="button">
        <a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
        <a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
    </div>
</div>
<div class="item">
    <div class="text">
        <div class="titulo">Amantes del yaoi y Yuri Lovers</div>
        <div class="subtitulo">11 enero, 2012</div>
    </div>
    <div class="button">
        <a href="./#"><img src="/img/interna/libro/icon/descarga.png" alt=""></a>
        <a href="./#"><img src="/img/interna/libro/icon/play.png" alt=""></a>
    </div>
</div>`;

  // Details

  const items_banner = [
    {
      foto_movil : libroData.fotoCover || (libroData.imagePaths && libroData.imagePaths[1] && `data:image/jpeg;base64,${libroData.imagePaths[1].base64}`)  || "/path/to/default/cover_mobile.png",
      foto: libroData.fotoCover  || (libroData.imagePaths && libroData.imagePaths[1] && `data:image/jpeg;base64,${libroData.imagePaths[1].base64}`) || "/path/to/default/cover_desktop.png",
      texto: `Nuestro Libros`,
    },
  ];

  const ibanner = [
    {
      foto_movil: "/img/banner/slider-trasparente.png",
      foto:  "/img/banner/slider-trasparente.png",
      texto: ``,
    },
  ];
  /* Data acordeon */
  const AcordeonData01 = [
    {
      titulo: "Nombres alternativos",
      alias: "alternativos",
      contenido: contenido01,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData02 = [
    {
      titulo: "Información: ",
      alias: "information01",
      contenido: contenido02,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData03 = [
    {
      titulo: "Publicado por:",
      alias: "publicao",
      contenido: contenido03,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData04 = [
    {
      titulo: "Categorias:",
      alias: "categorias",
      contenido: contenido04,
      open: true,
      show: "show",
    },
  ];
  const AcordeonData05 = [
    {
      titulo: "Etiquetas:",
      alias: "etiqueta",
      contenido: contenido05,
      open: true,
      show: "show",
    },
  ];
  const AcordeonDataChapters = [
    {
      titulo: "Detalles",
      alias: "chapter",
      contenido: contentChapters,
      open: true,
      show: "show",
    },
  ];
  const AcordeonDataDetailsItem = [
    {
      titulo: headContentItem,
      alias: "detailItem",
      contenido: contentDetailsItem,
      open: true,
      show: "show",
    },
  ];
  const [itemsRecommendation, setItemsRecommendation] = useState([]);
  const tituloItemBlue = `
    <div class="lbro col cel_gato">
        <img src="/img/interna/libro/icon/gato.png" alt="">
    </div>
    <div class="lbro col cel_data">
        <div class="celdata_sub titulo">
            WillcaftHD_23
            <div class="globo_red">Lv8</div>
           
        </div>
        <div class="celdata_sub subtitulo">
            <img class="icon_estrella" src="/img/interna/libro/icon/estrella.png" alt=""> 4.02 (53) Hace 2 días
        </div>
    </div>`;
  const contentItemBlue = `
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
        a type specimen book... <a class="red" href="./#">ver más</a></p>
        <div class="lbox_grupo fotos_tws oculta_movile">
                            <img class="foto_99_96" src="/img/interna/libro/foto1.png" alt="">
                            <img class="foto_99_96" src="/img/interna/libro/foto1.png" alt="">
                        </div>
                        <div class="lbox_grupo likes oculta_movile">
                        <img class="like" src="/img/interna/libro/icon/like.png" alt="">
                        <div class="texto">Responder <span>|</span> Reportar</div>
                    </div>`;
  const AcordeonDataDetailsItemBlue = [
    {
      titulo: tituloItemBlue,
      alias: "detailItem",
      contenido: contentItemBlue,
      open: true,
      show: "show",
    },
  ];

  // CONFIG CARRUSEL RECOMENDACION
  var configRecomendacion = {
    rows: 1,
    draggable: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: { slidesToShow: 2, slidesToScroll: 2, rows: 1 },
  };

  /* RECOMENDATION */
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {},
    };
    fetch(EndpointCore.recommends, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let data_items = [];
        result.items.forEach((i, index) => {
          data_items.push({
            id: index + 1,
            foto: i.images[0].image.path,
            texto: i.bookTitle,
            capitulo: "capitulo", //i.latestChapter.group.name,
            novela: i.bookType.name.value,
            //"autor": i.bookAuthor,
            time: "Hace " + i.attributes.bookHourLatest + " horas",
            manga: "Seinen", //i.attributes.name.value,
            shoujo: "Novela", //i.bookType.name.value,
            puntuacion: i.bookRate,
            vistas: i.bookLabelViews,
          });
        });
        setItemsRecommendation(data_items);
      })
      .catch((error) => console.log("error", error));
  }, []);

  // TAB MOVIL
  const contenidoInformacion = `<p>Qin Tian fue llevado a una isla desierta debido a un naufragio. Todo lo que tiene equipado es un sistema de supervivencia y una navaja. Tiene que empezar de cero. Construyendo un campamento, haciendo fuego y pescando, tiene
    que hacer todo lo posible para sobrevivir. ¡Afortunadamente, hay muchas bellezas que lo acompañan! Diosa de la escuela, hermana rubia, loli violenta...</p> `;
  console.log('capitulos', chapters)
  console.log('es nueva agrupacion', isNewGroup)
return (
  <div className="interna libros detail">
    <div className="position_header position hide_movil">
      <div className="ui_header hide_movil">
        <Menu></Menu>
      </div>
      <div className="ui_header hide_desk">
        <ul className="header-icon-mobile-detalle-libro">
          <li className="icon1">
            <a href="libros.html">
              <img src="/img/interna/libro_detalle/m-prev.png" alt="" />
            </a>
          </li>
          <li className="icon2">
            <img src="/img/interna/libro_detalle/m-share.png" alt="" />
            <img src="/img/interna/libro_detalle/m-estrella.png" alt="" />
            <img src="/img/interna/libro_detalle/m-tres.png" alt="" />
          </li>
        </ul>
      </div>
      <div className="top-banner">
        <Banner items_banner={ibanner}></Banner>
      </div>
    </div>
    <div className="position_top_A position">
      <div className="container" style={{ display: 'flex', paddingLeft: '0px' }}>
        <div className="pcontent">
          <div className="banner-simple hide_movil">
            <Banner items_banner={items_banner}></Banner>
            <div className="social">
              <a href="./#">
                <img src="/img/interna/libro/icon/social1.png" alt="" />
              </a>
              <a href="./#">
                <img src="/img/interna/libro/icon/social2.png" alt="" />
              </a>
              <a href="./#">
                <img src="/img/interna/libro/icon/social3.png" alt="" />
              </a>
            </div>
          </div>
          <div className="cardInfo">
            <LittleBookEdit btnTitle="Subir capitulo" libroData={libroData} handleSubmit={handleSubmit}></LittleBookEdit>
          </div>
          <div className="acordeonType2 cardInfoChapters padding-right_10 d-flex" style={{ flexDirection: "column" }}>
            <div className="box-row subtitulo margin-button-20">
              <span>subir capítulo </span>
            </div>
            <label className="form-check-label" htmlFor="type2" style={{ fontWeight: "bold", color: "black" }}>
              CREAR CAPÍTULO
            </label>
            <div style={!isNovel ? { display: "flex", alignItems: "center" } : { display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
              <div className="ibillete">
                {isNovel && (
                  <div className="group-date historia">
                    <span>N° de Volumen</span>
                    <input
                      className=""
                      type="text"
                      name="volumeNumber"
                      value={chapterData.volumeNumber}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="group-date historia">
        <span>N° de Capítulo</span>
        {!isNewGroup ? (
        <input
          className=""
          type="text"
          name="chapterNumber"
          value={chapterData.chapterNumber}
          onChange={handleChange}
        />
      ) : (
        <input
          type="text"
          name="chapterNumber"
          value={chapterData.chapterNumber}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readOnly={!isEditable}
        />
        )}

        {isNewGroup && isDropdownVisible && (
          <ul className="dropdown">
            {chapters.map((chapter) => (
              <li key={chapter.number} onClick={() => handleSelect(chapter.number)}>
                Capítulo {chapter.number}
              </li>
            ))}
            <li onClick={() => handleSelect('new')}>Agregar Nuevo Capítulo</li>
          </ul>
        )}
      </div>
      </div>
              <div className="ibillete">
                <div className="group-date historia">
                  <span>Nombre del Capítulo</span>
                  <input
                    className=""
                    type="text"
                    name="chapterName"
                    value={chapterData.chapterName}
                    onChange={handleChange}
                    disabled={isNewGroup && !isEditable && chapterData.chapterNumber !== 'new'} 
                  />
                </div>
              </div>
            </div>
            {isNewGroup ? (
              <div className="ibillete">
                <div className="group-date historia">
                  <DropdownAgrupacion
                    options={groupsData}
                    onSelect={setSelectedAgrupacion}
                    label="Seleccionar Agrupación"
                  />
                </div>
              </div>
            ) : null}
            <br />
            <label className="form-check-label" htmlFor="type2" style={{ fontWeight: "bold" }}>
              APARIENCIA
            </label>
            <label className="form-check-label" htmlFor="type2">
              Tener una buena ortografía es importante
            </label>
            <div className="mt-5">
              {isNovel ? (
                <div className="inputBook" style={{ display: "flex", marginRight: 10, padding: "5px 10px" }}>
                  <ReactQuill
                    theme="snow"
                    placeholder="Escribe tu historia"
                    modules={{
                      toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image', 'video'],
                        ['clean']
                      ],
                    }}
                    value={chapterData.content}
                    onChange={handleContentChange}
                    readOnly={isEditorDisabled}
                  />
                </div>
              ) : !isNewGroup && (
                <div style={{ display: 'flex', marginBottom: '25px' }}>
                  <div style={{ width: "107px", height: "82px" }}>
                    <img
                      src={chapterProfileImage ? chapterProfileImage : "/img/interna/profile/logotipo.png"}
                      style={{ maxWidth: "100%", maxHeight: "100%", marginRight: "10px", borderRadius: "15px" }}
                    />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <div className="form-check-label" style={{ fontWeight: "bold" }}>
                      Imagen de perfil
                    </div>
                    <div className="form-check-label" style={{ fontWeight: "500" }}>
                      Recomendado 800x80px
                    </div>
                    <div className="inputBook" style={{ display: "flex", marginRight: 10, padding: "5px 10px" }}>
                      <FileUploader
                        handleChange={handleChapterProfileImage}
                        name="file"
                        types={["JPG", "PNG", "GIF"]}
                        label={"Seleccionar imágenes"}
                        dropMessageStyle={{ backgroundColor: "#c1c1c1" }}
                      >
                        <div style={{ display: "flex", marginRight: 10, padding: "5px 10px", width: "196px", height: "32px", justifyContent: "center" }}>
                          <img src="/img/image.png" style={{ width: "20", marginRight: "10px" }} />
                          Seleccionar imagen
                        </div>
                      </FileUploader>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex">
                <div className="inputBook" style={{ display: "flex", marginRight: 10, padding: "5px 10px" }}>
                  <FileUploader
                    handleChange={handleImageChange}
                    name="file"
                    types={["JPG", "PNG", "GIF"]}
                    label={"Subir imágenes"}
                    dropMessageStyle={{ backgroundColor: "#c1c1c1" }}
                    multiple={true}
                    disabled={isFileDisabled}
                  >
                    <div style={{ display: "flex", marginRight: 10, padding: "5px 10px", width: "165px", height: "32px", justifyContent: "center" }}>
                      <img src="/img/image.png" style={{ width: "20", marginRight: "10px" }} />
                      Subir imágenes
                    </div>
                  </FileUploader>
                </div>
                <div className="inputBook" style={{ display: "flex", marginRight: 10, padding: "5px 10px" }}>
                  <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    types={["PDF"]}
                    label={"Subir PDF"}
                    dropMessageStyle={{ backgroundColor: "#c1c1c1" }}
                    multiple={false}
                    disabled={isFileDisabled}
                  >
                    <div style={{ width: "120px", height: "32px", display: "flex", marginRight: 10, padding: "5px 10px", justifyContent: "center" }}>
                      <img src="/img/pdf.png" style={{ width: "20", marginRight: "10px" }} />
                      Subir PDF
                    </div>
                  </FileUploader>
                </div>
              </div>
                <div
                  className="mt-5"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn_rosado"
                    onClick={handleSubmit}
                  >
                    Subir capítulo
                  </button>
              </div>
            
            </div>
          
          </div>
          </div>
          <div className="psidebar sin_padding">
            <div className="cardInfo hide_desk">
              <LittleBookEdit libroData={libroData}></LittleBookEdit>
            </div>
            <div className="Tabs hide_desk">
              <div className="tabs">
                <div className="icard-header">
                  <div className="icar-h4">
                    <div className="mi-titulo">{"titulo"}</div>
                  </div>
                </div>
                <div className="scroll-cabecera">
                  <div className="mi-scroll">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li key={"key_position01"} className="nav-item">
                        <a
                          className={`nav-link active`}
                          id={"tab_information"}
                          data-toggle="tab"
                          href={"#information"}
                          role="tab"
                          aria-controls={"information"}
                          aria-selected={"true"}
                        >
                          {"Información"}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link`}
                          id={"tab_capitulos"}
                          data-toggle="tab"
                          href={"#capitulos"}
                          role="tab"
                          aria-controls={"capitulos"}
                          aria-selected={"false"}
                        >
                          {"Capítulos"}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link`}
                          id={"tab_comentarios"}
                          data-toggle="tab"
                          href={"#comentarios"}
                          role="tab"
                          aria-controls={"comentarios"}
                          aria-selected={"false"}
                        >
                          {"Comentarios"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-content fade show active"
                  id="information"
                  dangerouslySetInnerHTML={{ __html: contenidoInformacion }}
                  role="tabpanel"
                  aria-labelledby={"tab_information"}
                ></div>
                <div
                  className="tab-content fade"
                  id="capitulos"
                  role="tabpanel"
                  aria-labelledby={"tab_capitulos"}
                >
                  <div className="acordeonType3 cardInfoDetailsItem">
                    <Acordeon AcordeonData={AcordeonDataDetailsItem}></Acordeon>
                  </div>
                </div>
                <div
                  className="tab-content fade icomentario"
                  id="comentarios"
                  role="tabpanel"
                  aria-labelledby={"tab_comentarios"}
                >
                  <div class="lbox_bold oculta_movile">Comentarios:</div>
                  <div className="acordeonType3 cardInfoDetailsItem blue">
                    <Acordeon
                      AcordeonData={AcordeonDataDetailsItemBlue}
                    ></Acordeon>
                  </div>
                </div>
              </div>
            </div>

            <div className="padding_14">
              <div className="acordeonType1">
                <Acordeon AcordeonData={AcordeonData01}></Acordeon>
              </div>
              <div className="acordeonType1">
                <Acordeon AcordeonData={AcordeonData02}></Acordeon>
              </div>
              <div className="acordeonType1">
                <Acordeon AcordeonData={AcordeonData03}></Acordeon>
              </div>
              <div className="acordeonType1 grupo_guinda">
                <Acordeon AcordeonData={AcordeonData04}></Acordeon>
              </div>
              <div className="acordeonType1 grupo_red">
                <Acordeon AcordeonData={AcordeonData05}></Acordeon>
              </div>
            </div>
            <div className="bgblue color_white icomentario">
              <div class="lbox_bold oculta_movile hide_comentario">
                Comentarios:
              </div>
              <div className="acordeonType3 cardInfoDetailsItem blue hide_comentario">
                <Acordeon AcordeonData={AcordeonDataDetailsItemBlue}></Acordeon>
              </div>
              <div className="container recomendation">
                <Carrusel
                  icarrusel={itemsRecommendation}
                  key_position={"key003"}
                  titulo={"Recomendados"}
                  config={configRecomendacion}
                ></Carrusel>
              </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default BookUploadChapter;

import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./style.css";

const DropdownAgrupacion = ({ options, onSelect, label }) => {
  const [selectedId, setSelectedId] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedId(option.id);
    onSelect(option.id); // Pasar el id al onSelect
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown-custom w-100" onClick={() => setIsOpen(!isOpen)}>
      <div className="d-flex align-items-center">
        <span>{label}</span>

        {/* Mostrar la opción seleccionada cuando sea diferente a label */}
        {selectedId && (
          <div className="selected-option d-flex align-items-center">
            {options.find(option => option.id === selectedId).images?.[0]?.base64 && (
              <img
                src={`data:image/png;base64,${options.find(option => option.id === selectedId).images[0].base64}`}
                alt={`Group ${selectedId}`}
                className="group-image"
              />
            )}
            <span>{options.find(option => option.id === selectedId).name}</span>
          </div>
        )}
        {!isOpen ? (
          <BiChevronDown color="#5F36DA" />
        ) : (
          <BiChevronUp color="#5F36DA" />
        )}
      </div>

      {isOpen && (
        <ul className="dropdown-menu-custom">
          {options.map((option) => (
            <li key={option.id} onClick={() => handleOptionSelect(option)} className="d-flex align-items-center">
              {option.images?.[0]?.base64 && (
                <div className="group-image">
                <img
                  src={`data:image/png;base64,${option.images[0].base64}`}
                  alt={`Group ${option.id}`}
                  className="dropdown-image"
                />
                </div>
              )}
              <span>{option.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownAgrupacion;

import React, { useState } from "react";

export default function CardLibraryNoLogin({ book, isSelection, selectedBooks, handleCheckboxChange }) {
  return (
    <li className="ibanner">
      <div className="item">
        {isSelection && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={book.id} // Se utiliza el id del libro para el checkbox
              id={`book-${book.id}`} // Identificador único para el checkbox
              onChange={(e) => handleCheckboxChange(e, book.id)} // Manejo del cambio de estado
              checked={selectedBooks.includes(book.id)} // Verificar si el libro está seleccionado
            />
          </div>
        )}
        <img src={book.profileImage} alt="Imagen del libro" />
      </div>
      <div className="item text">{book.title}</div>
      <div className="item estrellita">
        <div className="num-rosado">21</div>
        <div className="cap">Cap.96</div>
      </div>
    </li>
  );
}
import "./style.css";
import React, { useEffect, useState } from "react";
import Switch from "../switch/Switch";
import EndpointCore from "../../pages/Global";
import ProfileModal from "../profileModal";
import bob from '../../assets/img/Icono6.svg';
import Modal from '../modal/Modal';

function Menu() {
  const [items_menu, setitems_menu] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function filename() {
    var rutaAbsoluta = window.location.href;
    var posicionUltimaBarra = rutaAbsoluta.lastIndexOf("/");
    var rutaRelativa = rutaAbsoluta.substring(
      posicionUltimaBarra + "/".length,
      rutaAbsoluta.length
    );
    return rutaRelativa;
  }
  
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: { "Content-Type": "application/json" },
    };
    fetch(EndpointCore.navbar, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let data_items = [];
        result.items.forEach((i) => {
          data_items.push({
            active: false,
            texto: i.name.value,
            url: i.linkRelative.value,
          });
        });
        data_items[0].active = true;
        setitems_menu(data_items);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const url = filename();
  const protocol = window.location.protocol;
  const host = window.location.host;
  const protocolAndHost = protocol + "//" + host;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isUserLoggedIn = localStorage.getItem('token');

  return (
    <div className="menu">
      <div className="logo">
        <a>
          <img src={bob} className="bob-logo oculta_desktop"/>
        </a>
        <a href="/" className="brand-logo hide_movil">
          Dmangas
        </a>
        <Switch />
      </div> 
      <nav className="top-nav" style={{ position: "relative" }}>
        <ul>
          {items_menu.map((value, idx) => (
            <li
              key={"menu01_" + idx}
              id={"menu_" + (idx + 1)}
              className="ui_nav__li hide_movil"
            >
              <a
                href={"/" + value.url}
                className={`ui_nav__a ${value.url === url ? "ui_active" : ""}`}
              >
                {value.texto}
              </a>
            </li>
          ))}
          <li id="menu_6" className="ui_nav__li">
            <div className="button">
              <button id="open_buscar" type="button">
                <img
                  loading="lazy"
                  className="blue"
                  src={`${protocolAndHost}/img/svg/icon_search.svg`}
                  alt=""
                />
                <img
                  loading="lazy"
                  className="white"
                  src={`${protocolAndHost}/img/svg/icon_mobile/icon_search_white.svg`}
                  alt=""
                />
              </button>
              <button id="open_registro" type="button" onClick={toggleModal}>
                <img
                  loading="lazy"
                  className="blue"
                  src={`${protocolAndHost}/img/svg/icon_user.svg`}
                  alt=""
                />
                <img
                  loading="lazy"
                  className="white"
                  src={`${protocolAndHost}/img/svg/icon_mobile/icon_user_white.svg`}
                  alt=""
                />
              </button>
            </div>
          </li>
        </ul>
        </nav>

        {isUserLoggedIn ? (
          <div
            className="w-32 h-12 hidden"
            style={{ position: "absolute", right: 0 }}
          >
            {isModalOpen && <ProfileModal />}
          </div>
        ) : (
          <Modal imodal={'inicio'} isOpen={isModalOpen} onClose={toggleModal} />
        )}

    </div>
  );
}

export default Menu;

import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import EndpointCore from '../../../pages/Global';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import checkedAvatar from '../../../assets/img/svg/icon_check_white.svg'
import avatar1 from '../../../assets/img/login/avatar-selected/avatar1.png'
import avatar2 from '../../../assets/img/login/avatar-selected/avatar2.png'
import avatar3 from '../../../assets/img/login/avatar-selected/avatar3.png'
import avatar4 from '../../../assets/img/login/avatar-selected/avatar4.png'
import avatar5 from '../../../assets/img/login/avatar-selected/avatar5.png'
import avatar6 from '../../../assets/img/login/avatar-selected/avatar6.png'
import avatar7 from '../../../assets/img/login/avatar-selected/avatar7.png'
import avatar8 from '../../../assets/img/login/avatar-selected/avatar8.png'


function Unete({ cambioVentana, userInfo }) {
  const [perfilSeleccionado, setPerfilSeleccionado] = useState(false);
  const [perfilSeleccionadoNombre, setPerfilSeleccionadoNombre] = useState('Selecciona tu Perfil');
  const [apodo, setApodo] = useState('');
  const [correoElectronico, setCorreoElectronico] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [userType, setUserType] = useState(null);
  const [dropdownOpen, setDropdownOpen] =useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [terminosAceptados, setTerminosAceptados] = useState(false);


  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8]  
  
  const handleRegistro = () => {

      if (!apodo || (userInfo ? false : !correoElectronico) || (userInfo ? false : !contrasena) || userType === null) {
        toastr.error('Todos los campos son obligatorios.');
        return;
      }
  
      if (!userInfo && !isValidEmail(correoElectronico)) {
        toastr.error('Por favor, ingresa un correo electrónico válido.');
        return;
      }

      if (!terminosAceptados) {
        toastr.error('Debes aceptar los términos para participar.');
        return;
      }

    fetch(EndpointCore.NODE_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_name: apodo,
        email: userInfo ? userInfo.email : correoElectronico,
        password: userInfo ? '' : contrasena,
        user_type: userType,
        sub: userInfo ? userInfo.sub : null,
      }),
    })
      .then(response => response.json())
      .then(data => {

        toastr.success(data.msg);
        const token = data.token;

       if (data.loginMethod === 'google') {
       localStorage.setItem('token', token);
       window.location.reload();
       } else {
       toastr.info('Verifica tu correo electrónico para confirmar tu cuenta.');
       }
      })
      .catch(error => {
        console.error('Error al registrar:', error);
        const errorMsg = error.response ? error.response.data.msg : 'Error al registrar. Por favor, inténtalo de nuevo.';
        
        toastr.error(errorMsg);
      });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleAvatarClick = (index) => {
    if (selectedAvatar === index) {
      setSelectedAvatar(null);
    } else {
      setSelectedAvatar(index);
    }
  };
  const handleCheckboxChange = () => {
    setTerminosAceptados(!terminosAceptados);
  };
  
 
  return (
    <div className="html">
      <ul>
        <li>
          <ul id="ul-unete" className="info-login">
            <li><span>Únete ahora</span></li>
            <li>
              <span>Regístrate para empezar a disfrutar las mejores historias en español</span>
            </li>
            <li>
            <div className="caja">
          <label>Apodo</label>
          <input className='custom-input-text' type="text" value={apodo} onChange={(e) => setApodo(e.target.value)} />
        </div>
      </li>
      {!userInfo && (
              <>
                <li>
                  <div className="caja">
                    <label>Correo electrónico</label>
                    <input className='custom-input-text' type="email" value={correoElectronico} onChange={(e) => setCorreoElectronico(e.target.value)} />
                  </div>
                </li>
                <li>
                  <div className="caja">
                    <label>Contraseña</label>
                    <input className='custom-input-text' type="password" value={contrasena} onChange={(e) => setContrasena(e.target.value)} />
                  </div>
                </li>
              </>
            )}
            <li>
              <div className="caja sinborde">
              <Dropdown className='custom-dropdown' onToggle={(isOpen) => setDropdownOpen(isOpen)}>
                <Dropdown.Toggle className={`custom-dropdown ${dropdownOpen ? 'custom-toggle-open' : 'custom-toggle-closed'}`} variant="secondary" id="dropdown-basic">
                    <span id="dropdown_id">{perfilSeleccionadoNombre}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='custom-dropdown-menu'>
                    <Dropdown.Item onClick={() => {
                      setPerfilSeleccionado(true);
                      setPerfilSeleccionadoNombre('Akari VanHove');
                      setUserType(0);
                      setDropdownOpen(!dropdownOpen);
                    }}>
                      <button className="dropdown-item dropdown-item--ui" type="button" >
                      <div className="ui_dropdown_circle" >
                      </div>
                      <img className='dropdown-item-img' src='/img/login/avatar/akari.png'/>
                        <span>Akari VanHove</span>
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      setPerfilSeleccionado(true);
                      setPerfilSeleccionadoNombre('Nathan Vanhove');
                      setUserType(1);
                      setDropdownOpen(!dropdownOpen);
                   
                    }}>
                      <button className="dropdown-item dropdown-item--ui" type="button">
                      <div className="ui_dropdown_circle">
                      </div>
                      <img className='dropdown-item-img'src='/img/login/avatar/nathan.png'/>
                        <span>Nathan Vanhove</span>
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li>
             {perfilSeleccionado && (
            <li>
              <label className="subtitulo">Define tu avatar:</label>
              <ul className="perfil-avatar">
                {avatars.map((avatar, index) => (
                  <li key={index} className="perfil-avatar-item" style={{ position: 'relative' }}>
                    <img
                      loading="lazy"
                      src={avatar}
                      alt=""
                      onClick={() => handleAvatarClick(index)}
                      style={{ cursor: 'pointer' }}
                      className='img-item'
                    />
                    {selectedAvatar === index && (
                        <img
                          src={checkedAvatar}
                          style={{ width: '20px', height: '20px'}}
                        />
                    )}
                  </li>
                ))}
              </ul>
                </li>
              )}

              <div className="form-check" >
                <div>
                <input
                className="form-check-input"
                type="checkbox"
                id="type1"
                checked={terminosAceptados}
                onChange={handleCheckboxChange}
              />                </div>
                <p>Términos, condiciones y políticas de privacidad</p>
              </div>
            <li>
              <button className="btn-rosado" type="button" onClick={handleRegistro}>
                Regístrate
              </button>
            </li>
            <li className='linea_de_puntos'></li>
            <li><span>¿Ya tienes una cuenta?</span></li>
              <li><span><a  onClick={() => cambioVentana('registro')}>Ingresa ahora</a></span></li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Unete;
import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./style.css";

const DropdownAttribute = ({ options, onSelect, label, initialValue }) => {
  const [selectedValues, setSelectedValues] = useState(initialValue || []);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (option) => {
    let newSelectedValues;
    if (selectedValues.includes(option.value)) {
      newSelectedValues = selectedValues.filter(value => value !== option.value);
    } else {
      newSelectedValues = [...selectedValues, option.value];
    }
    setSelectedValues(newSelectedValues);
    onSelect(newSelectedValues);
  };

  return (
    <div className="custom-dropdown-custom w-100" onClick={() => setIsOpen(!isOpen)}>
      <div className="d-flex justify-content-between">
        <span>{label}</span>
        <span>{selectedValues.length > 0 ? selectedValues.join(", ") : "Seleccionar"}</span>
        {!isOpen ? (
          <BiChevronDown color="#5F36DA" />
        ) : (
          <BiChevronUp color="#5F36DA" />
        )}
      </div>

      {isOpen && (
        <ul className="dropdown-menu-custom">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionSelect(option)}>
              {option.label} {selectedValues.includes(option.value) ? "(✓)" : ""}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownAttribute;

import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";

export default function ContenidoOriginalModal({ show, setShow }) {
  const handleClose = () => setShow(false);

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="contenido-original-modal">
      <Modal.Header className="custom-modal-header">
          <button className="custom-close-button" onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" className="bi bi-x" viewBox="0 0 16 16">
              <path  fill="#ffffff" fillRule="evenodd" d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l4.646-4.647a.5.5 0 1 1 .708.708L8.707 8l4.647 4.646a.5.5 0 1 1-.708.708L8 8.707l-4.646 4.647a.5.5 0 0 1-.708-.708L7.293 8 2.646 3.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="modal-section first" style={{display:"block", textAlign: "center", alignItems:"center"}} closeButton>
              <h5 className="contenido-original">Contenido Original</h5>
              <h6>Seleccionar la/las opciones</h6>        
            </div>
            <div className="modal-section">
              <input type="checkbox" className="checkbox-input" />
              <div>
                <h5>Todos los derechos reservados:</h5>
                <p>No permites que tu trabajo se utilizado o adaptado, bajo ningún concepto, sin tu permiso.</p>
              </div>
            </div>
            <div className="modal-section">
              <input type="checkbox" className="checkbox-input" />
              <div>
                <h5>Dominio Público:</h5>
                <p>Esto permite que cualquier persona use tu historia para cualquier propósito - cualquiera puede, de forma gratuita, imprimir, vender o convertir en película tu trabajo.</p>
              </div>
            </div>
            <div className="modal-section">
              <input type="checkbox" className="checkbox-input" />
              <div>
                <h5>Atribución de Creative Commons (CC)</h5>
                <p>Mantienes algunos derechos sobre tu historia pero concedes ciertos permisos como traducciones o adaptaciones, siempre y cuando recibas el crédito correspondiente.</p>
              </div>
            </div>
            <div className="modal-section">
              <input type="checkbox" className="checkbox-input" />
              <div>
                <h5>Atribución de (CC) No Comercial:</h5>
                <p>Esta licencia permite que otros remezclen, adapten y creen sobre tu trabajo, sin fines comerciales, siempre y cuando te den el crédito correspondiente.</p>
              </div>
            </div>
            <div className="modal-section">
              <input type="checkbox" className="checkbox-input" />
              <div>
                <h5>Atribución de (CC) no Com. Sin Derivados:</h5>
                <p>Esta licencia permite a otros compartir tu trabajo sin alteraciones, siempre y cuando  te den el crédito por el mismo.</p>
              </div>
            </div>
            <div className="modal-section">
              <input type="checkbox" className="checkbox-input" />
              <div>
                <h5>Atribución de (CC) no Com. SheareAlike:</h5>
                <p>Esto permite que otros se adapten y desarrollen tu trabajo, incluso con fines comerciales, siempre y cuando te den crédito por el mismo y las mismas condiciones sean aplicadas</p>
              </div>
            </div>
            <div className="modal-section" style ={{border:'none'}}>
              <input type="checkbox" className="checkbox-input" />
              <div>
                <h5>Atribución de (CC). Sin derivados:</h5>
                <p>Esto permite a otros reutilizar tu trabajo para cualquier propósito, sin embargo no puede ser alterado y deben darte crédito por el mismo.</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

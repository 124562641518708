import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import CustomImageLoader from '../../CustomLoader/CustomImageLoader';
import "./style.css";
import EndpointCore from '../../../pages/Global';

function GoogleLogin({ setGoogleLoginInfo, cambioVentana, buttonText }) {
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = async (response) => {
    try {
      setIsLoading(true);

      const userInfoRes = await fetch(process.env.REACT_APP_GOOGLE_API_RES, {
        headers: {
          "Authorization": `Bearer ${response.access_token}`
        }
      });

      if (!userInfoRes.ok) {
        throw new Error("Error al obtener los datos del usuario Google");
      }

      const userInfo = await userInfoRes.json();

      // Verificar si el usuario ya está registrado
    const userExistsResponse = await fetch(EndpointCore.googleLogin, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userInfo }),
    });

    const userExistsData = await userExistsResponse.json();

    if (userExistsResponse.ok) {

      localStorage.setItem('token', userExistsData.token);
      window.location.reload();
    
    } else {
      // Si el usuario no está registrado, redirige a la ventana "Unete"
      setGoogleLoginInfo(userInfo);
      cambioVentana('unete');
    }

  } catch (error) {
    console.error(error);
  } finally {
    setIsLoading(false);
  }
};

  const login = useGoogleLogin({
    onSuccess
  });

  return (
    <div className='spinner-login'>
      <a href="/#" onClick={(event) => { login(); event.preventDefault(); }}>
        <img src="/img/svg/icon_google.svg" alt="" />
        {isLoading && <CustomImageLoader/>}
        <span>{buttonText}</span>
      </a>
    </div>
  );
}

export default GoogleLogin;